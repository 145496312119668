const formMapper = {
  Input: "text",
  Email: "email",
  Select: "select",
  Radio: "radio",
  Date: "date",
  Checkbox: "checkbox",
  "Date Range": "date-range",
  "Single select": "select",
  "Large text": "textarea",
  "Multi select": "multi-select",
  Multiselect: "multi-select",
  Consent: "consent",
  Canvas: "signature",
  signature: "signature",
  File: "file-list",
  file: "file-list",
  Label: "label",
  label: "label",
  LABEL: "label",
};

const TENANT_ROLES_MAP = {
  field: {
    read: "READ_FIELD",
    create: "CREATE_FIELD",
    delete: "DELETE_FIELD",
    edit: "EDIT_FIELD",
  },
  block: {
    read: "READ_BLOCK",
    create: "CREATE_BLOCK",
    delete: "DELETE_BLOCK",
    edit: "EDIT_BLOCK",
  },
  check: {
    read: "READ_CHECK",
    create: "CREATE_CHECK",
    delete: "DELETE_CHECK",
    edit: "EDIT_CHECK",
  },
  package: {
    readList: "READ_PACKAGE_LIST",
    read: "READ_PACKAGE",
    create: "CREATE_PACKAGE",
    delete: "DELETE_PACKAGE",
    edit: "EDIT_PACKAGE",
  },
  form: {
    read: "READ_ADDITIONAL_FORM",
    create: "CREATE_ADDITIONAL_FORM",
    delete: "DELETE_ADDITIONAL_FORM",
    edit: "EDIT_ADDITIONAL_FORM",
  },
  // CASE SPECIFIC ROLES
  case: {
    read: "READ_CASE",
    create: "CREATE_CASE",
    delete: "DELETE_CASE",
    allocate: "CASE_ALLOCATION",
    // profile action
    activity_history: "ACTIVITY_HISTORY",
    actions_internal: "CASE_ACTIONS_INTERNAL",
    actions_external: "CASE_ACTIONS_EXTERNAL_COMMUNICATION",
    // tabs
    read_input: "READ_INPUT",
    read_write_input: "READ_WRITE_INPUT",
    read_check_screen: "R_CHECK_SPECIFIC_SCREEN",
    read_write_check_screen: "RW_CHECK_SPECIFIC_SCREEN",
    read_output: "READ_OUTPUT",
    write_output: "WRITE_OUTPUT",
    case_allocation: "EDIT_CASE_ALLOCATION",
    // file folder
    internal_folder: "RW_INTERNAL_FOLDER",
    // read_write_internal_folder: 'RW_INTERNAL_FOLDER'
    edit: "EDIT_CASE_ALLOCATION",
    status_update: "UPDATE_CASE_STATUS",
  },

  // case_status: {
  //     update: "UPDATE_CASE_STATUS"9
  // },
  // case_allocation: {
  //     edit: 'EDIT_CASE_ALLOCATION'
  // },

  client: {
    read: "READ_CLIENT",
    readList: "READ_CLIENTS_LIST",
    create: "CREATE_CLIENT",
    delete: "DELETE_CLIENT",
    edit: "EDIT_CLIENT",
  },
  dashboard: {
    read: "READ_DASHBOARD",
  },
  users: {
    read: "READ_USERS",
    create: "CREATE_USER",
    delete: "DELETE_USER",
    edit: "EDIT_USER",
  },
  groups: {
    read: "READ_GROUPS",
    create: "CREATE_GROUP",
    delete: "DELETE_GROUP",
    edit: "EDIT_GROUP",
  },
  role: {
    read: "READ_ROLE",
    create: "ADD_ROLE",
    delete: "DELETE_ROLE",
    edit: "EDIT_ROLE",
    add: "ADD_ROLE",
  },
  email: {
    read: "READ_EMAIL_TEMPLATES",
    create: "CREATE_EMAIL_TEMPLATE",
    delete: "DELETE_EMAIL_TEMPLATE",
    edit: "EDIT_EMAIL_TEMPLATE",
  },
  sequence: {
    read: "READ_EMAIL_SEQUENCES",
    create: "CREATE_EMAIL_SEQUENCE",
    delete: "DELETE_EMAIL_SEQUENCE",
    edit: "EDIT_EMAIL_SEQUENCE",
    list: "READ_EMAIL_SEQUENCE_LIST",
    templateList: "READ_EMAIL_TEMPLATE_LIST",
    deafult: "READ_TENANT_DEFAULT_TEMPLATE_SEQUENCES",
  },
  theme: {
    edit: "EDIT_THEME_AND_LOGO",
  },
  customDomain: {
    read: "READ_CUSTOM_DOMAIN_CUSTOM_EMAIL",
    edit: "EDIT_CUSTOM_DOMAIN_CUSTOM_EMAIL",
  },
  defaultEmailSequence: {
    read: "READ_TENANT_DEFAULT_TEMPLATE_SEQUENCES",
    edit: "EDIT_TENANT_DEFAULT_TEMPLATE_SEQUENCES",
  },
  tax: {
    read: "READ_CREATE_TAX",
    activate: "ACTIVATE_DEACTIVATE_TAX",
    allocate: "ALLOCATE_TAX",
    createRead: "READ_CREATE_TAX",
    edit: "EDIT_TAX",
    create: "CREATE_TAX",
  },
  invoice: {
    read: "READ_PURCHASE_INVOICE",
    print: "EMAIL_PRINT_PURCHASE_INVOICE",
    create: "CREATE_PURCHASE_INVOICE",
  },
  history: {
    activity: "ACTIVITY_HISTORY",
  },
  source: {
    read: "READ_SOURCE_USAGE",
  },
  action: {
    internal: "CASE_ACTIONS_INTERNAL",
  },
  inventory: {
    read: "READ_INVENTORY",
  },
  input: {
    read: "READ_WRITE_INPUT",
  },
  creditsAllocation: {
    read: "READ_CREDIT_ALLOCATION",
  },
  screen: {
    read: "RW_CHECK_SPECIFIC_SCREEN",
  },
  creditsRequest: {
    read: "READ_CREDIT_REQUEST",
  },
  salesInvoice: {
    read: "READ_CREATE_SALES_INVOICE",
    create: "CREATE_SALES_INVOICE",
  },
  purchaseOrder: {
    create: "CREATE_PURCHASE_ORDER",
    approve: "APPROVE_REJECT_PURCHASE_ORDER",
    read: "READ_CREATE_PURCHASE_ORDER",
  },
  salesOrder: {
    read: "READ_CREATE_SALES_ORDER",
    approve: "APPROVE_REJECT_SALES_ORDER",
    create: "CREATE_SALES_ORDER",
  },
  creditPack: {
    read: "READ_CREDIT_PACK",
    allocate: "ALLOCATE_CREDIT_PACK",
  },
  license: {
    read: "READ_LICENSE",
    delete: "DELETE_LICENSE",
    edit: "EDIT_LICENSE",
    create: "CREATE_LICENSE",
  },
  salesPayment: {
    approve: "APPROVE_SALES_PAYMENT",
  },
  domain: {
    read: "READ_CUSTOM_DOMAIN_CUSTOM_EMAIL",
    edit: "EDIT_CUSTOM_DOMAIN_CUSTOM_EMAIL",
  },
  workflow: {
    create: "CREATE_WORKFLOW",
    read: "READ_WORKFLOW",
    edit: "EDIT_WORKFLOW"
  },

  // "read_field":
  // create_field: "CREATE_FIELD",

  //

  // read_block: "READ_BLOCK",
  // create_block: "CREATE_BLOCK",
  // edit_block: "EDIT_BLOCK",
  // delete_block: "DELETE_BLOCK",
  // read_check: "READ_CHECK",
  // "CREATE_CHECK",
  // "EDIT_CHECK",
  // "DELETE_CHECK"
};

const allMonths = [
  {
    key: 1,
    small_label: "Jan",
    large_label: "Januray",
  },
  {
    key: 2,
    small_label: "feb",
    large_label: "February",
  },
  {
    key: 3,
    small_label: "Mar",
    large_label: "March",
  },
  {
    key: 4,
    small_label: "Apr",
    large_label: "April",
  },
  {
    key: 5,
    small_label: "May",
    large_label: "May",
  },
  {
    key: 6,
    small_label: "Jun",
    large_label: "June",
  },
  {
    key: 7,
    small_label: "July",
    large_label: "July",
  },
  {
    key: 8,
    small_label: "Aug",
    large_label: "August",
  },
  {
    key: 9,
    small_label: "Sep",
    large_label: "September ",
  },
  {
    key: 10,
    small_label: "Oct",
    large_label: "October",
  },
  {
    key: 11,
    small_label: "Nov",
    large_label: "November",
  },
  {
    key: 12,
    small_label: "Dec",
    large_label: "December",
  },
];

const ENTITY_TYPES_DROPDOWN_CONFIG = Object.freeze({
    label: 'name',
    trackBy: 'id',
})

// In this case, can't be deselected. Better to be determined dynamically on the BE:
const DEFAULT_PACKAGE_ENTITY_TYPE = Object.freeze({
    key: 'name',
    value: 'Case',
});

const MINIMUM_ENTITY_COUNT = 1;
const MAXIMUM_ENTITY_COUNT = null;

export const REMOVED_ENTITIES = ["associated_entity", "social_profile", "volunteering_roles", "wider_evidence_skills", "charitable_work", "active_media", "search", "passive_media"];

export const ADVERSE_TOOL_MAPPING = {
    name: {
        complyAdvantage: {keys: ["doc.name"]},
        worldCheck: {keys: ["first_name", "last_name"], separator: " "},
        dowJones: {keys: ["name[0]"], separator: ", ", iterable_key: ""},
        offshoreLeaks: {keys: ["name"], separator: " "},
        lexisBridger: {keys: ["data.EntityDetails.Name.Full"], separator: " "},
    },
    aka_names: {
        complyAdvantage: {keys: ["doc.aka"], separator: ", ", iterable_key: "name"},
        worldCheck: {keys: ["aliases"], separator: " "},
        dowJones: {keys: ["aka"], separator: " "},
        offshoreLeaks: {keys: [], separator: " "},
        lexisBridger: {keys: ["data.EntityDetails.AKAs"], separator: ", ", iterable_key: "Name.Full"},
    },
    types: {
        complyAdvantage: {keys: ["doc.types"], separator: ", "},
        worldCheck: {keys: ["types"], separator: " "},
        dowJones: {keys: ["types"], separator: " "},
        offshoreLeaks: {keys: [], separator: " "},
        lexisBridger: {keys: ["data.ReasonListed"], separator: ", "},
    },
    entity_type: {
        complyAdvantage: {keys: ["doc.entity_type"], separator: ", "},
        worldCheck: {keys: ["entity_type"], separator: ", "},
        dowJones: {keys: ["entity_type"], separator: " "},
        offshoreLeaks: {keys: ["data_type"], separator: " "},
        lexisBridger: {keys: ["data.EntityDetails.EntityType"], separator: ", "},
    },
    jurisdiction: {
        complyAdvantage: {keys: []},
        worldCheck: {keys: []},
        dowJones: {keys: ["wl.CountryDetails", "soe.CountryDetails", "ame.CountryDetails"], separator: ", ", iterable_key: "CountryValue[0]['@name']"},
        offshoreLeaks: {keys: ["jurisdiction_description"], separator: " "},
        lexisBridger: {keys: ["data.EntityDetails.Addresses"], separator: ", ", iterable_key: "Country"},
    },
    created_at: {
        complyAdvantage: {keys: ["doc.created_utc"]},
        worldCheck: {keys: ["entered"]},
        dowJones: {keys: []},
        offshoreLeaks: {keys: []},
        lexisBridger: {keys: ["data.EntityDetails.DateListed"]},
    },
    updated_at: {
        complyAdvantage: {keys: ["doc.last_updated_utc"]},
        worldCheck: {keys: ["updated"]},
        dowJones: {keys: []},
        offshoreLeaks: {keys: []},
        lexisBridger: {keys: ["data.DateModified"]},
    },
    locations: {
        complyAdvantage: {keys: ["locations"]},
        worldCheck: {keys: ["locations"]},
        dowJones: {keys: ["locations"]},
        offshoreLeaks: {keys: ["locations"]},
        lexisBridger: {keys: []},
    },
    dob: {
        complyAdvantage: {keys: ["dob"]},
        worldCheck: {keys: ["dob"]},
        dowJones: {keys: ["dob"]},
        offshoreLeaks: {keys: [""]},
        lexisBridger: {keys: [""]},
    },
    score: {
        complyAdvantage: {keys: [""]},
        worldCheck: {keys: [""]},
        dowJones: {keys: [""]},
        offshoreLeaks: {keys: [""]},
        lexisBridger: {keys: ["data.BestNameScore"]},
    },
};
export const CORPORATE_TOOL_MAPPING = {
  company_number: {
      openCorporates: {keys: ["company.company_number"]},
      clarifiedBy: {keys: ["registration_number"]},
      companyWatch: {keys: ["nationalID"]},
      bvd: {keys: ["COMPANY_ID_NUMBER[0]"]},
      db: {keys: ["data.organization.registrationNumbers"], iterable_key: "registrationNumber"},
      officer: {keys: ["officer.company.company_number"]},
      officer_clarifiedBy: {keys: ["officer.company.company_number"]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: ["CONTACTS_MEMBERSHIP_IdCompany"]},
      address: {keys: ["company.company_number"]},
  },
  duns_number: {
      openCorporates: {keys: [""]},
      clarifiedBy: {keys: [""]},
      companyWatch: {keys: [""]},
      bvd: {keys: [""]},
      db: {keys: ["data.organization.duns"]},
      officer: {keys: [""]},
      officer_clarifiedBy: {keys: [""]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: [""]},
  },
  company_name: {
      openCorporates: {keys: ["company.name"]},
      clarifiedBy: {keys: ["company_name.name_en"]},
      companyWatch: {keys: ["name"]},
      bvd: {keys: ["NAME"]},
      db: {keys: ["data.organization.primaryName"]},
      officer: {keys: ["officer.company.name"]},
      officer_clarifiedBy: {keys: ["officer.company.name"]},
      officer_companyWatch: {keys: []},
      officer_bvd: {keys: ["CONTACTS_MEMBERSHIP_NameCompany"]},
      address: {keys: ["company.name"]},
  },
  status: {
      openCorporates: {keys: ["company.current_status"]},
      clarifiedBy: {keys: ["status.name_en"]},
      companyWatch: {keys: [""]},
      db: {keys: ["data.organization.dunsControlStatus.isOutOfBusiness", "data.organization.dunsControlStatus.operatingStatus.isOutOfBusiness", "data.organization.dunsControlStatus.operatingStatus.description"]},
      bvd: {keys: ["STATUS_INFO[0].STATUS"]},
      officer: {keys: ["officer.current_status"]},
      officer_clarifiedBy: {keys: ["record_status.status_name"]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: ["company.current_status"]},
  },
  address: {
      openCorporates: {keys: ["company.registered_address_in_full"]},
      clarifiedBy: {keys: ["city_of_registration.name_en", "country_of_registration.name_en"], separator: ", "},
      companyWatch: {keys: ["address"]},
      bvd: {
          keys: ["ADDRESS_LINE1", "ADDRESS_LINE2", "ADDRESS_LINE3", "ADDRESS_LINE4", "CITY", "COUNTRY"],
          separator: ", ",
      },
      db: {
          keys: ["data.organization.primaryAddress.streetAddress.line1", "data.organization.primaryAddress.streetAddress.line2", "data.organization.primaryAddress.addressLocality.name", "data.organization.primaryAddress.postalCode", "data.organization.primaryAddress.addressRegion.name", "data.organization.primaryAddress.addressCountry.isoAlpha2Code", "data.organization.primaryAddress.addressCountry.name"],
          separator: ",",
      },
      officer: {keys: ["officer.address"]},
      officer_clarifiedBy: {keys: ["officer.address"]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: ["company.registered_address_in_full"]},
  },
  officer_name: {
      openCorporates: {keys: []},
      clarifiedBy: {keys: []},
      companyWatch: {keys: []},
      db: {keys: ["data.organization.mostSeniorPrincipals"], iterable_key: "fullName"},
      bvd: {keys: [""]},
      officer: {keys: [""]},
      officer_clarifiedBy: {keys: [""]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: [""]},
  },
  phone_number: {
      openCorporates: {keys: []},
      clarifiedBy: {keys: []},
      companyWatch: {keys: []},
      db: {keys: ["data.organization.telephone"], iterable_key: "telephoneNumber"},
      bvd: {keys: [""]},
      officer: {keys: [""]},
      officer_clarifiedBy: {keys: [""]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: [""]},
  },
  isMail_undeliverable: {
      openCorporates: {keys: []},
      clarifiedBy: {keys: []},
      companyWatch: {keys: []},
      db: {keys: ["data.organization.telephone.isUnreachable"], iterable_key: "isUnreachable"},
      bvd: {keys: [""]},
      officer: {keys: [""]},
      officer_clarifiedBy: {keys: [""]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: [""]},
  },
  company_type: {
      openCorporates: {keys: ["company.company_type"]},
      clarifiedBy: {keys: ["legal_form.name_en"]},
      companyWatch: {keys: [""]},
      db: {keys: [""]},
      bvd: {keys: ["NATIONAL_LEGAL_FORM"]},
      officer: {keys: ["officer.company_type"]},
      officer_clarifiedBy: {keys: ["officer.company_type"]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: ["company.company_type"]},
  },
  jurisdiction: {
      openCorporates: {keys: ["company.jurisdiction_code"]},
      clarifiedBy: {keys: ["country_code"]},
      companyWatch: {keys: ["isoCountry"]},
      bvd: {keys: ["COUNTRY_ISO_CODE"]},
      officer: {keys: ["officer.jurisdiction_code"]},
      officer_clarifiedBy: {keys: ["officer.jurisdiction_code"]},
      officer_companyWatch: {keys: ["productCode"]},
      officer_bvd: {keys: [""]},
      db: {keys: ["data.organization.primaryAddress.addressCountry.isoAlpha2Code"]},
      address: {keys: ["company.jurisdiction_code"]},
  },
  incorporation_date: {
      openCorporates: {keys: ["company.incorporation_date"]},
      clarifiedBy: {keys: [""]},
      companyWatch: {keys: [""]},
      db: {keys: [""]},
      bvd: {keys: ["INCORPORATION_DATE"]},
      officer: {keys: ["officer.incorporation_date"]},
      officer_clarifiedBy: {keys: ["officer.incorporation_date"]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: ["CONTACTS_MEMBERSHIP_BeginningNominationDate"]},
      address: {keys: ["company.incorporation_date"]},
  },
  score: {
      openCorporates: {keys: [""]},
      clarifiedBy: {keys: [""]},
      companyWatch: {keys: [""]},
      db: {keys: ["data.matchQualityInformation.confidenceCode"]},
      bvd: {keys: [""]},
      officer: {keys: [""]},
      officer_clarifiedBy: {keys: [""]},
      officer_companyWatch: {keys: [""]},
      officer_bvd: {keys: [""]},
      address: {keys: [""]},
  },
};
const DEFAULT_ENTITY_TYPE_NAME = 'Case';

const ENTITY_TYPE_NAMES = Object.freeze({
    CASE: 'Case',
    PERSON: 'Person',
    COMPANY: 'Company',
});

const EDD_CHECK_ID = '92b5f0c9-f341-485e-9c70-87d6276491f8';

const MAXIMUM_CUSTOMIZED_FORMS_COUNT = 5;

export const JURISDICTION_MAP = [
  {
      jurisdiction: {
          code: "ad",
          name: "Andorra",
          country: "Andorra",
          full_name: "Andorra",
      },
  },
  {
      jurisdiction: {
          code: "ae_az",
          name: "Abu Dhabi",
          country: "United Arab Emirates",
          full_name: "Abu Dhabi (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "ae_du",
          name: "Dubai",
          country: "United Arab Emirates",
          full_name: "Dubai (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "af",
          name: "Afghanistan",
          country: "Afghanistan",
          full_name: "Afghanistan",
      },
  },
  {
      jurisdiction: {
          code: "ag",
          name: "Antigua and Barbuda",
          country: "Antigua and Barbuda",
          full_name: "Antigua and Barbuda",
      },
  },
  {
      jurisdiction: {
          code: "ai",
          name: "Anguilla",
          country: "Anguilla",
          full_name: "Anguilla",
      },
  },
  {
      jurisdiction: {
          code: "al",
          name: "Albania",
          country: "Albania",
          full_name: "Albania",
      },
  },
  {
      jurisdiction: {
          code: "am",
          name: "Armenia",
          country: "Armenia",
          full_name: "Armenia",
      },
  },
  {
      jurisdiction: {
          code: "ao",
          name: "Angola",
          country: "Angola",
          full_name: "Angola",
      },
  },
  {
      jurisdiction: {
          code: "aq",
          name: "Antarctica",
          country: "Antarctica",
          full_name: "Antarctica",
      },
  },
  {
      jurisdiction: {
          code: "ar",
          name: "Argentina",
          country: "Argentina",
          full_name: "Argentina",
      },
  },
  {
      jurisdiction: {
          code: "as",
          name: "American Samoa",
          country: "American Samoa",
          full_name: "American Samoa",
      },
  },
  {
      jurisdiction: {
          code: "at",
          name: "Austria",
          country: "Austria",
          full_name: "Austria",
      },
  },
  {
      jurisdiction: {
          code: "au",
          name: "Australia",
          country: "Australia",
          full_name: "Australia",
      },
  },
  {
      jurisdiction: {
          code: "aw",
          name: "Aruba",
          country: "Aruba",
          full_name: "Aruba",
      },
  },
  {
      jurisdiction: {
          code: "ax",
          name: "Åland Islands",
          country: "Åland Islands",
          full_name: "Åland Islands",
      },
  },
  {
      jurisdiction: {
          code: "az",
          name: "Azerbaijan",
          country: "Azerbaijan",
          full_name: "Azerbaijan",
      },
  },
  {
      jurisdiction: {
          code: "ba",
          name: "Bosnia and Herzegovina",
          country: "Bosnia and Herzegovina",
          full_name: "Bosnia and Herzegovina",
      },
  },
  {
      jurisdiction: {
          code: "bb",
          name: "Barbados",
          country: "Barbados",
          full_name: "Barbados",
      },
  },
  {
      jurisdiction: {
          code: "bd",
          name: "Bangladesh",
          country: "Bangladesh",
          full_name: "Bangladesh",
      },
  },
  {
      jurisdiction: {
          code: "be",
          name: "Belgium",
          country: "Belgium",
          full_name: "Belgium",
      },
  },
  {
      jurisdiction: {
          code: "bf",
          name: "Burkina Faso",
          country: "Burkina Faso",
          full_name: "Burkina Faso",
      },
  },
  {
      jurisdiction: {
          code: "bg",
          name: "Bulgaria",
          country: "Bulgaria",
          full_name: "Bulgaria",
      },
  },
  {
      jurisdiction: {
          code: "bh",
          name: "Bahrain",
          country: "Bahrain",
          full_name: "Bahrain",
      },
  },
  {
      jurisdiction: {
          code: "bi",
          name: "Burundi",
          country: "Burundi",
          full_name: "Burundi",
      },
  },
  {
      jurisdiction: {
          code: "bj",
          name: "Benin",
          country: "Benin",
          full_name: "Benin",
      },
  },
  {
      jurisdiction: {
          code: "bl",
          name: "Saint Barthélemy",
          country: "Saint Barthélemy",
          full_name: "Saint Barthélemy",
      },
  },
  {
      jurisdiction: {
          code: "bm",
          name: "Bermuda",
          country: "Bermuda",
          full_name: "Bermuda",
      },
  },
  {
      jurisdiction: {
          code: "bn",
          name: "Brunei Darussalam",
          country: "Brunei Darussalam",
          full_name: "Brunei Darussalam",
      },
  },
  {
      jurisdiction: {
          code: "bo",
          name: "Bolivia",
          country: "Bolivia",
          full_name: "Bolivia",
      },
  },
  {
      jurisdiction: {
          code: "bq",
          name: "Bonaire, Sint Eustatius and Saba",
          country: "Bonaire, Sint Eustatius and Saba",
          full_name: "Bonaire, Sint Eustatius and Saba",
      },
  },
  {
      jurisdiction: {
          code: "br",
          name: "Brazil",
          country: "Brazil",
          full_name: "Brazil",
      },
  },
  {
      jurisdiction: {
          code: "bs",
          name: "Bahamas",
          country: "Bahamas",
          full_name: "Bahamas",
      },
  },
  {
      jurisdiction: {
          code: "bt",
          name: "Bhutan",
          country: "Bhutan",
          full_name: "Bhutan",
      },
  },
  {
      jurisdiction: {
          code: "bv",
          name: "Bouvet Island",
          country: "Bouvet Island",
          full_name: "Bouvet Island",
      },
  },
  {
      jurisdiction: {
          code: "bw",
          name: "Botswana",
          country: "Botswana",
          full_name: "Botswana",
      },
  },
  {
      jurisdiction: {
          code: "by",
          name: "Belarus",
          country: "Belarus",
          full_name: "Belarus",
      },
  },
  {
      jurisdiction: {
          code: "bz",
          name: "Belize",
          country: "Belize",
          full_name: "Belize",
      },
  },
  {
      jurisdiction: {
          code: "ca",
          name: "Canada",
          country: "Canada",
          full_name: "Canada",
      },
  },
  {
      jurisdiction: {
          code: "ca_ab",
          name: "Alberta",
          country: "Canada",
          full_name: "Alberta (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_bc",
          name: "British Columbia",
          country: "Canada",
          full_name: "British Columbia (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_mb",
          name: "Manitoba",
          country: "Canada",
          full_name: "Manitoba (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_nb",
          name: "New Brunswick",
          country: "Canada",
          full_name: "New Brunswick (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_nl",
          name: "Newfoundland and Labrador",
          country: "Canada",
          full_name: "Newfoundland and Labrador (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_ns",
          name: "Nova Scotia",
          country: "Canada",
          full_name: "Nova Scotia (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_on",
          name: "Ontario",
          country: "Canada",
          full_name: "Ontario (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_pe",
          name: "Prince Edward Island",
          country: "Canada",
          full_name: "Prince Edward Island (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_qc",
          name: "Quebec",
          country: "Canada",
          full_name: "Quebec (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_sk",
          name: "Saskatchewan",
          country: "Canada",
          full_name: "Saskatchewan (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_nt",
          name: "Northwest Territories",
          country: "Canada",
          full_name: "Northwest Territories (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_nu",
          name: "Nunavut",
          country: "Canada",
          full_name: "Nunavut (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "ca_yt",
          name: "Yukon",
          country: "Canada",
          full_name: "Yukon (Canada)",
      },
  },
  {
      jurisdiction: {
          code: "cc",
          name: "Cocos (Keeling) Islands",
          country: "Cocos (Keeling) Islands",
          full_name: "Cocos (Keeling) Islands",
      },
  },
  {
      jurisdiction: {
          code: "cd",
          name: "Democratic Republic of the Congo",
          country: "Democratic Republic of the Congo",
          full_name: "Democratic Republic of the Congo",
      },
  },
  {
      jurisdiction: {
          code: "cf",
          name: "Central African Republic",
          country: "Central African Republic",
          full_name: "Central African Republic",
      },
  },
  {
      jurisdiction: {
          code: "cg",
          name: "Congo",
          country: "Congo",
          full_name: "Congo",
      },
  },
  {
      jurisdiction: {
          code: "ch",
          name: "Switzerland",
          country: "Switzerland",
          full_name: "Switzerland",
      },
  },
  {
      jurisdiction: {
          code: "ci",
          name: "Côte d'Ivoire",
          country: "Côte d'Ivoire",
          full_name: "Côte d'Ivoire",
      },
  },
  {
      jurisdiction: {
          code: "ck",
          name: "Cook Islands",
          country: "Cook Islands",
          full_name: "Cook Islands",
      },
  },
  {
      jurisdiction: {
          code: "cl",
          name: "Chile",
          country: "Chile",
          full_name: "Chile",
      },
  },
  {
      jurisdiction: {
          code: "cm",
          name: "Cameroon",
          country: "Cameroon",
          full_name: "Cameroon",
      },
  },
  {
      jurisdiction: {
          code: "cn",
          name: "China",
          country: "China",
          full_name: "China",
      },
  },
  {
      jurisdiction: {
          code: "co",
          name: "Colombia",
          country: "Colombia",
          full_name: "Colombia",
      },
  },
  {
      jurisdiction: {
          code: "cr",
          name: "Costa Rica",
          country: "Costa Rica",
          full_name: "Costa Rica",
      },
  },
  {
      jurisdiction: {
          code: "cu",
          name: "Cuba",
          country: "Cuba",
          full_name: "Cuba",
      },
  },
  {
      jurisdiction: {
          code: "cv",
          name: "Cape Verde",
          country: "Cape Verde",
          full_name: "Cape Verde",
      },
  },
  {
      jurisdiction: {
          code: "cw",
          name: "Curaçao",
          country: "Curaçao",
          full_name: "Curaçao",
      },
  },
  {
      jurisdiction: {
          code: "cx",
          name: "Christmas Island",
          country: "Christmas Island",
          full_name: "Christmas Island",
      },
  },
  {
      jurisdiction: {
          code: "cy",
          name: "Cyprus",
          country: "Cyprus",
          full_name: "Cyprus",
      },
  },
  {
      jurisdiction: {
          code: "cz",
          name: "Czech Republic",
          country: "Czech Republic",
          full_name: "Czech Republic",
      },
  },
  {
      jurisdiction: {
          code: "de",
          name: "Germany",
          country: "Germany",
          full_name: "Germany",
      },
  },
  {
      jurisdiction: {
          code: "dj",
          name: "Djibouti",
          country: "Djibouti",
          full_name: "Djibouti",
      },
  },
  {
      jurisdiction: {
          code: "dk",
          name: "Denmark",
          country: "Denmark",
          full_name: "Denmark",
      },
  },
  {
      jurisdiction: {
          code: "dm",
          name: "Dominica",
          country: "Dominica",
          full_name: "Dominica",
      },
  },
  {
      jurisdiction: {
          code: "do",
          name: "Dominican Republic",
          country: "Dominican Republic",
          full_name: "Dominican Republic",
      },
  },
  {
      jurisdiction: {
          code: "dz",
          name: "Algeria",
          country: "Algeria",
          full_name: "Algeria",
      },
  },
  {
      jurisdiction: {
          code: "ec",
          name: "Ecuador",
          country: "Ecuador",
          full_name: "Ecuador",
      },
  },
  {
      jurisdiction: {
          code: "ee",
          name: "Estonia",
          country: "Estonia",
          full_name: "Estonia",
      },
  },
  {
      jurisdiction: {
          code: "eg",
          name: "Egypt",
          country: "Egypt",
          full_name: "Egypt",
      },
  },
  {
      jurisdiction: {
          code: "eh",
          name: "Western Sahara",
          country: "Western Sahara",
          full_name: "Western Sahara",
      },
  },
  {
      jurisdiction: {
          code: "er",
          name: "Eritrea",
          country: "Eritrea",
          full_name: "Eritrea",
      },
  },
  {
      jurisdiction: {
          code: "es",
          name: "Spain",
          country: "Spain",
          full_name: "Spain",
      },
  },
  {
      jurisdiction: {
          code: "et",
          name: "Ethiopia",
          country: "Ethiopia",
          full_name: "Ethiopia",
      },
  },
  {
      jurisdiction: {
          code: "fi",
          name: "Finland",
          country: "Finland",
          full_name: "Finland",
      },
  },
  {
      jurisdiction: {
          code: "fj",
          name: "Fiji",
          country: "Fiji",
          full_name: "Fiji",
      },
  },
  {
      jurisdiction: {
          code: "fk",
          name: "Falkland Islands (Malvinas)",
          country: "Falkland Islands (Malvinas)",
          full_name: "Falkland Islands (Malvinas)",
      },
  },
  {
      jurisdiction: {
          code: "fm",
          name: "Micronesia, Federated States of",
          country: "Micronesia, Federated States of",
          full_name: "Micronesia, Federated States of",
      },
  },
  {
      jurisdiction: {
          code: "fo",
          name: "Faroe Islands",
          country: "Faroe Islands",
          full_name: "Faroe Islands",
      },
  },
  {
      jurisdiction: {
          code: "fr",
          name: "France",
          country: "France",
          full_name: "France",
      },
  },
  {
      jurisdiction: {
          code: "ga",
          name: "Gabon",
          country: "Gabon",
          full_name: "Gabon",
      },
  },
  {
      jurisdiction: {
          code: "gb",
          name: "United Kingdom",
          country: "United Kingdom",
          full_name: "United Kingdom",
      },
  },
  {
      jurisdiction: {
          code: "gd",
          name: "Grenada",
          country: "Grenada",
          full_name: "Grenada",
      },
  },
  {
      jurisdiction: {
          code: "ge",
          name: "Georgia",
          country: "Georgia",
          full_name: "Georgia",
      },
  },
  {
      jurisdiction: {
          code: "gf",
          name: "French Guiana",
          country: "French Guiana",
          full_name: "French Guiana",
      },
  },
  {
      jurisdiction: {
          code: "gg",
          name: "Guernsey",
          country: "Guernsey",
          full_name: "Guernsey",
      },
  },
  {
      jurisdiction: {
          code: "gh",
          name: "Ghana",
          country: "Ghana",
          full_name: "Ghana",
      },
  },
  {
      jurisdiction: {
          code: "gi",
          name: "Gibraltar",
          country: "Gibraltar",
          full_name: "Gibraltar",
      },
  },
  {
      jurisdiction: {
          code: "gl",
          name: "Greenland",
          country: "Greenland",
          full_name: "Greenland",
      },
  },
  {
      jurisdiction: {
          code: "gm",
          name: "Gambia",
          country: "Gambia",
          full_name: "Gambia",
      },
  },
  {
      jurisdiction: {
          code: "gn",
          name: "Guinea",
          country: "Guinea",
          full_name: "Guinea",
      },
  },
  {
      jurisdiction: {
          code: "gp",
          name: "Guadeloupe",
          country: "Guadeloupe",
          full_name: "Guadeloupe",
      },
  },
  {
      jurisdiction: {
          code: "gq",
          name: "Equatorial Guinea",
          country: "Equatorial Guinea",
          full_name: "Equatorial Guinea",
      },
  },
  {
      jurisdiction: {
          code: "gr",
          name: "Greece",
          country: "Greece",
          full_name: "Greece",
      },
  },
  {
      jurisdiction: {
          code: "gs",
          name: "South Georgia and the South Sandwich Islands",
          country: "South Georgia and the South Sandwich Islands",
          full_name: "South Georgia and the South Sandwich Islands",
      },
  },
  {
      jurisdiction: {
          code: "gt",
          name: "Guatemala",
          country: "Guatemala",
          full_name: "Guatemala",
      },
  },
  {
      jurisdiction: {
          code: "gu",
          name: "Guam",
          country: "Guam",
          full_name: "Guam",
      },
  },
  {
      jurisdiction: {
          code: "gw",
          name: "Guinea-Bissau",
          country: "Guinea-Bissau",
          full_name: "Guinea-Bissau",
      },
  },
  {
      jurisdiction: {
          code: "gy",
          name: "Guyana",
          country: "Guyana",
          full_name: "Guyana",
      },
  },
  {
      jurisdiction: {
          code: "hk",
          name: "Hong Kong",
          country: "Hong Kong",
          full_name: "Hong Kong",
      },
  },
  {
      jurisdiction: {
          code: "hm",
          name: "Heard Island and McDonald Islands",
          country: "Heard Island and McDonald Islands",
          full_name: "Heard Island and McDonald Islands",
      },
  },
  {
      jurisdiction: {
          code: "hn",
          name: "Honduras",
          country: "Honduras",
          full_name: "Honduras",
      },
  },
  {
      jurisdiction: {
          code: "hr",
          name: "Croatia",
          country: "Croatia",
          full_name: "Croatia",
      },
  },
  {
      jurisdiction: {
          code: "ht",
          name: "Haiti",
          country: "Haiti",
          full_name: "Haiti",
      },
  },
  {
      jurisdiction: {
          code: "hu",
          name: "Hungary",
          country: "Hungary",
          full_name: "Hungary",
      },
  },
  {
      jurisdiction: {
          code: "id",
          name: "Indonesia",
          country: "Indonesia",
          full_name: "Indonesia",
      },
  },
  {
      jurisdiction: {
          code: "ie",
          name: "Ireland",
          country: "Ireland",
          full_name: "Ireland",
      },
  },
  {
      jurisdiction: {
          code: "il",
          name: "Israel",
          country: "Israel",
          full_name: "Israel",
      },
  },
  {
      jurisdiction: {
          code: "im",
          name: "Isle of Man",
          country: "Isle of Man",
          full_name: "Isle of Man",
      },
  },
  {
      jurisdiction: {
          code: "in",
          name: "India",
          country: "India",
          full_name: "India",
      },
  },
  {
      jurisdiction: {
          code: "io",
          name: "British Indian Ocean Territory",
          country: "British Indian Ocean Territory",
          full_name: "British Indian Ocean Territory",
      },
  },
  {
      jurisdiction: {
          code: "iq",
          name: "Iraq",
          country: "Iraq",
          full_name: "Iraq",
      },
  },
  {
      jurisdiction: {
          code: "ir",
          name: "Iran",
          country: "Iran",
          full_name: "Iran",
      },
  },
  {
      jurisdiction: {
          code: "is",
          name: "Iceland",
          country: "Iceland",
          full_name: "Iceland",
      },
  },
  {
      jurisdiction: {
          code: "it",
          name: "Italy",
          country: "Italy",
          full_name: "Italy",
      },
  },
  {
      jurisdiction: {
          code: "je",
          name: "Jersey",
          country: "Jersey",
          full_name: "Jersey",
      },
  },
  {
      jurisdiction: {
          code: "jm",
          name: "Jamaica",
          country: "Jamaica",
          full_name: "Jamaica",
      },
  },
  {
      jurisdiction: {
          code: "jo",
          name: "Jordan",
          country: "Jordan",
          full_name: "Jordan",
      },
  },
  {
      jurisdiction: {
          code: "jp",
          name: "Japan",
          country: "Japan",
          full_name: "Japan",
      },
  },
  {
      jurisdiction: {
          code: "ke",
          name: "Kenya",
          country: "Kenya",
          full_name: "Kenya",
      },
  },
  {
      jurisdiction: {
          code: "kg",
          name: "Kyrgyzstan",
          country: "Kyrgyzstan",
          full_name: "Kyrgyzstan",
      },
  },
  {
      jurisdiction: {
          code: "kh",
          name: "Cambodia",
          country: "Cambodia",
          full_name: "Cambodia",
      },
  },
  {
      jurisdiction: {
          code: "ki",
          name: "Kiribati",
          country: "Kiribati",
          full_name: "Kiribati",
      },
  },
  {
      jurisdiction: {
          code: "km",
          name: "Comoros",
          country: "Comoros",
          full_name: "Comoros",
      },
  },
  {
      jurisdiction: {
          code: "kn",
          name: "Saint Kitts and Nevis",
          country: "Saint Kitts and Nevis",
          full_name: "Saint Kitts and Nevis",
      },
  },
  {
      jurisdiction: {
          code: "kp",
          name: "Korea, Democratic People's Republic of",
          country: "Korea, Democratic People's Republic of",
          full_name: "Korea, Democratic People's Republic of",
      },
  },
  {
      jurisdiction: {
          code: "kr",
          name: "Korea, Republic of",
          country: "Korea, Republic of",
          full_name: "Korea, Republic of",
      },
  },
  {
      jurisdiction: {
          code: "kw",
          name: "Kuwait",
          country: "Kuwait",
          full_name: "Kuwait",
      },
  },
  {
      jurisdiction: {
          code: "ky",
          name: "Cayman Islands",
          country: "Cayman Islands",
          full_name: "Cayman Islands",
      },
  },
  {
      jurisdiction: {
          code: "kz",
          name: "Kazakhstan",
          country: "Kazakhstan",
          full_name: "Kazakhstan",
      },
  },
  {
      jurisdiction: {
          code: "la",
          name: "Lao People's Democratic Republic",
          country: "Lao People's Democratic Republic",
          full_name: "Lao People's Democratic Republic",
      },
  },
  {
      jurisdiction: {
          code: "lb",
          name: "Lebanon",
          country: "Lebanon",
          full_name: "Lebanon",
      },
  },
  {
      jurisdiction: {
          code: "lc",
          name: "Saint Lucia",
          country: "Saint Lucia",
          full_name: "Saint Lucia",
      },
  },
  {
      jurisdiction: {
          code: "li",
          name: "Liechtenstein",
          country: "Liechtenstein",
          full_name: "Liechtenstein",
      },
  },
  {
      jurisdiction: {
          code: "lk",
          name: "Sri Lanka",
          country: "Sri Lanka",
          full_name: "Sri Lanka",
      },
  },
  {
      jurisdiction: {
          code: "lr",
          name: "Liberia",
          country: "Liberia",
          full_name: "Liberia",
      },
  },
  {
      jurisdiction: {
          code: "ls",
          name: "Lesotho",
          country: "Lesotho",
          full_name: "Lesotho",
      },
  },
  {
      jurisdiction: {
          code: "lt",
          name: "Lithuania",
          country: "Lithuania",
          full_name: "Lithuania",
      },
  },
  {
      jurisdiction: {
          code: "lu",
          name: "Luxembourg",
          country: "Luxembourg",
          full_name: "Luxembourg",
      },
  },
  {
      jurisdiction: {
          code: "lv",
          name: "Latvia",
          country: "Latvia",
          full_name: "Latvia",
      },
  },
  {
      jurisdiction: {
          code: "ly",
          name: "Libya",
          country: "Libya",
          full_name: "Libya",
      },
  },
  {
      jurisdiction: {
          code: "ma",
          name: "Morocco",
          country: "Morocco",
          full_name: "Morocco",
      },
  },
  {
      jurisdiction: {
          code: "mc",
          name: "Monaco",
          country: "Monaco",
          full_name: "Monaco",
      },
  },
  {
      jurisdiction: {
          code: "md",
          name: "Moldova",
          country: "Moldova",
          full_name: "Moldova",
      },
  },
  {
      jurisdiction: {
          code: "me",
          name: "Montenegro",
          country: "Montenegro",
          full_name: "Montenegro",
      },
  },
  {
      jurisdiction: {
          code: "mf",
          name: "Saint Martin (French part)",
          country: "Saint Martin (French part)",
          full_name: "Saint Martin (French part)",
      },
  },
  {
      jurisdiction: {
          code: "mg",
          name: "Madagascar",
          country: "Madagascar",
          full_name: "Madagascar",
      },
  },
  {
      jurisdiction: {
          code: "mh",
          name: "Marshall Islands",
          country: "Marshall Islands",
          full_name: "Marshall Islands",
      },
  },
  {
      jurisdiction: {
          code: "mk",
          name: "Macedonia",
          country: "Macedonia",
          full_name: "Macedonia",
      },
  },
  {
      jurisdiction: {
          code: "ml",
          name: "Mali",
          country: "Mali",
          full_name: "Mali",
      },
  },
  {
      jurisdiction: {
          code: "mm",
          name: "Myanmar",
          country: "Myanmar",
          full_name: "Myanmar",
      },
  },
  {
      jurisdiction: {
          code: "mn",
          name: "Mongolia",
          country: "Mongolia",
          full_name: "Mongolia",
      },
  },
  {
      jurisdiction: {
          code: "mo",
          name: "Macao",
          country: "Macao",
          full_name: "Macao",
      },
  },
  {
      jurisdiction: {
          code: "mp",
          name: "Northern Mariana Islands",
          country: "Northern Mariana Islands",
          full_name: "Northern Mariana Islands",
      },
  },
  {
      jurisdiction: {
          code: "mq",
          name: "Martinique",
          country: "Martinique",
          full_name: "Martinique",
      },
  },
  {
      jurisdiction: {
          code: "mr",
          name: "Mauritania",
          country: "Mauritania",
          full_name: "Mauritania",
      },
  },
  {
      jurisdiction: {
          code: "ms",
          name: "Montserrat",
          country: "Montserrat",
          full_name: "Montserrat",
      },
  },
  {
      jurisdiction: {
          code: "mt",
          name: "Malta",
          country: "Malta",
          full_name: "Malta",
      },
  },
  {
      jurisdiction: {
          code: "mu",
          name: "Mauritius",
          country: "Mauritius",
          full_name: "Mauritius",
      },
  },
  {
      jurisdiction: {
          code: "mv",
          name: "Maldives",
          country: "Maldives",
          full_name: "Maldives",
      },
  },
  {
      jurisdiction: {
          code: "mw",
          name: "Malawi",
          country: "Malawi",
          full_name: "Malawi",
      },
  },
  {
      jurisdiction: {
          code: "mx",
          name: "Mexico",
          country: "Mexico",
          full_name: "Mexico",
      },
  },
  {
      jurisdiction: {
          code: "my",
          name: "Malaysia",
          country: "Malaysia",
          full_name: "Malaysia",
      },
  },
  {
      jurisdiction: {
          code: "mz",
          name: "Mozambique",
          country: "Mozambique",
          full_name: "Mozambique",
      },
  },
  {
      jurisdiction: {
          code: "na",
          name: "Namibia",
          country: "Namibia",
          full_name: "Namibia",
      },
  },
  {
      jurisdiction: {
          code: "nc",
          name: "New Caledonia",
          country: "New Caledonia",
          full_name: "New Caledonia",
      },
  },
  {
      jurisdiction: {
          code: "ne",
          name: "Niger",
          country: "Niger",
          full_name: "Niger",
      },
  },
  {
      jurisdiction: {
          code: "nf",
          name: "Norfolk Island",
          country: "Norfolk Island",
          full_name: "Norfolk Island",
      },
  },
  {
      jurisdiction: {
          code: "ng",
          name: "Nigeria",
          country: "Nigeria",
          full_name: "Nigeria",
      },
  },
  {
      jurisdiction: {
          code: "ni",
          name: "Nicaragua",
          country: "Nicaragua",
          full_name: "Nicaragua",
      },
  },
  {
      jurisdiction: {
          code: "nl",
          name: "Netherlands",
          country: "Netherlands",
          full_name: "Netherlands",
      },
  },
  {
      jurisdiction: {
          code: "no",
          name: "Norway",
          country: "Norway",
          full_name: "Norway",
      },
  },
  {
      jurisdiction: {
          code: "np",
          name: "Nepal",
          country: "Nepal",
          full_name: "Nepal",
      },
  },
  {
      jurisdiction: {
          code: "nr",
          name: "Nauru",
          country: "Nauru",
          full_name: "Nauru",
      },
  },
  {
      jurisdiction: {
          code: "nu",
          name: "Niue",
          country: "Niue",
          full_name: "Niue",
      },
  },
  {
      jurisdiction: {
          code: "nz",
          name: "New Zealand",
          country: "New Zealand",
          full_name: "New Zealand",
      },
  },
  {
      jurisdiction: {
          code: "om",
          name: "Oman",
          country: "Oman",
          full_name: "Oman",
      },
  },
  {
      jurisdiction: {
          code: "pa",
          name: "Panama",
          country: "Panama",
          full_name: "Panama",
      },
  },
  {
      jurisdiction: {
          code: "pe",
          name: "Peru",
          country: "Peru",
          full_name: "Peru",
      },
  },
  {
      jurisdiction: {
          code: "pf",
          name: "French Polynesia",
          country: "French Polynesia",
          full_name: "French Polynesia",
      },
  },
  {
      jurisdiction: {
          code: "pg",
          name: "Papua New Guinea",
          country: "Papua New Guinea",
          full_name: "Papua New Guinea",
      },
  },
  {
      jurisdiction: {
          code: "ph",
          name: "Philippines",
          country: "Philippines",
          full_name: "Philippines",
      },
  },
  {
      jurisdiction: {
          code: "pk",
          name: "Pakistan",
          country: "Pakistan",
          full_name: "Pakistan",
      },
  },
  {
      jurisdiction: {
          code: "pl",
          name: "Poland",
          country: "Poland",
          full_name: "Poland",
      },
  },
  {
      jurisdiction: {
          code: "pm",
          name: "Saint Pierre and Miquelon",
          country: "Saint Pierre and Miquelon",
          full_name: "Saint Pierre and Miquelon",
      },
  },
  {
      jurisdiction: {
          code: "pn",
          name: "Pitcairn",
          country: "Pitcairn",
          full_name: "Pitcairn",
      },
  },
  {
      jurisdiction: {
          code: "pr",
          name: "Puerto Rico",
          country: "Puerto Rico",
          full_name: "Puerto Rico",
      },
  },
  {
      jurisdiction: {
          code: "ps",
          name: "Palestinian Territory",
          country: "Palestinian Territory",
          full_name: "Palestinian Territory",
      },
  },
  {
      jurisdiction: {
          code: "pt",
          name: "Portugal",
          country: "Portugal",
          full_name: "Portugal",
      },
  },
  {
      jurisdiction: {
          code: "pw",
          name: "Palau",
          country: "Palau",
          full_name: "Palau",
      },
  },
  {
      jurisdiction: {
          code: "py",
          name: "Paraguay",
          country: "Paraguay",
          full_name: "Paraguay",
      },
  },
  {
      jurisdiction: {
          code: "qa",
          name: "Qatar",
          country: "Qatar",
          full_name: "Qatar",
      },
  },
  {
      jurisdiction: {
          code: "re",
          name: "Réunion",
          country: "Réunion",
          full_name: "Réunion",
      },
  },
  {
      jurisdiction: {
          code: "ro",
          name: "Romania",
          country: "Romania",
          full_name: "Romania",
      },
  },
  {
      jurisdiction: {
          code: "rs",
          name: "Serbia",
          country: "Serbia",
          full_name: "Serbia",
      },
  },
  {
      jurisdiction: {
          code: "ru",
          name: "Russia",
          country: "Russia",
          full_name: "Russia",
      },
  },
  {
      jurisdiction: {
          code: "rw",
          name: "Rwanda",
          country: "Rwanda",
          full_name: "Rwanda",
      },
  },
  {
      jurisdiction: {
          code: "sa",
          name: "Saudi Arabia",
          country: "Saudi Arabia",
          full_name: "Saudi Arabia",
      },
  },
  {
      jurisdiction: {
          code: "sb",
          name: "Solomon Islands",
          country: "Solomon Islands",
          full_name: "Solomon Islands",
      },
  },
  {
      jurisdiction: {
          code: "sc",
          name: "Seychelles",
          country: "Seychelles",
          full_name: "Seychelles",
      },
  },
  {
      jurisdiction: {
          code: "sd",
          name: "Sudan",
          country: "Sudan",
          full_name: "Sudan",
      },
  },
  {
      jurisdiction: {
          code: "se",
          name: "Sweden",
          country: "Sweden",
          full_name: "Sweden",
      },
  },
  {
      jurisdiction: {
          code: "sg",
          name: "Singapore",
          country: "Singapore",
          full_name: "Singapore",
      },
  },
  {
      jurisdiction: {
          code: "sh",
          name: "Saint Helena, Ascension and Tristan da Cunha",
          country: "Saint Helena, Ascension and Tristan da Cunha",
          full_name: "Saint Helena, Ascension and Tristan da Cunha",
      },
  },
  {
      jurisdiction: {
          code: "si",
          name: "Slovenia",
          country: "Slovenia",
          full_name: "Slovenia",
      },
  },
  {
      jurisdiction: {
          code: "sj",
          name: "Svalbard and Jan Mayen",
          country: "Svalbard and Jan Mayen",
          full_name: "Svalbard and Jan Mayen",
      },
  },
  {
      jurisdiction: {
          code: "sk",
          name: "Slovakia",
          country: "Slovakia",
          full_name: "Slovakia",
      },
  },
  {
      jurisdiction: {
          code: "sl",
          name: "Sierra Leone",
          country: "Sierra Leone",
          full_name: "Sierra Leone",
      },
  },
  {
      jurisdiction: {
          code: "sm",
          name: "San Marino",
          country: "San Marino",
          full_name: "San Marino",
      },
  },
  {
      jurisdiction: {
          code: "sn",
          name: "Senegal",
          country: "Senegal",
          full_name: "Senegal",
      },
  },
  {
      jurisdiction: {
          code: "so",
          name: "Somalia",
          country: "Somalia",
          full_name: "Somalia",
      },
  },
  {
      jurisdiction: {
          code: "sr",
          name: "Suriname",
          country: "Suriname",
          full_name: "Suriname",
      },
  },
  {
      jurisdiction: {
          code: "ss",
          name: "South Sudan",
          country: "South Sudan",
          full_name: "South Sudan",
      },
  },
  {
      jurisdiction: {
          code: "st",
          name: "Sao Tome and Principe",
          country: "Sao Tome and Principe",
          full_name: "Sao Tome and Principe",
      },
  },
  {
      jurisdiction: {
          code: "sv",
          name: "El Salvador",
          country: "El Salvador",
          full_name: "El Salvador",
      },
  },
  {
      jurisdiction: {
          code: "sx",
          name: "Sint Maarten (Dutch part)",
          country: "Sint Maarten (Dutch part)",
          full_name: "Sint Maarten (Dutch part)",
      },
  },
  {
      jurisdiction: {
          code: "sy",
          name: "Syrian Arab Republic",
          country: "Syrian Arab Republic",
          full_name: "Syrian Arab Republic",
      },
  },
  {
      jurisdiction: {
          code: "sz",
          name: "Swaziland",
          country: "Swaziland",
          full_name: "Swaziland",
      },
  },
  {
      jurisdiction: {
          code: "tc",
          name: "Turks and Caicos Islands",
          country: "Turks and Caicos Islands",
          full_name: "Turks and Caicos Islands",
      },
  },
  {
      jurisdiction: {
          code: "td",
          name: "Chad",
          country: "Chad",
          full_name: "Chad",
      },
  },
  {
      jurisdiction: {
          code: "tf",
          name: "French Southern Territories",
          country: "French Southern Territories",
          full_name: "French Southern Territories",
      },
  },
  {
      jurisdiction: {
          code: "tg",
          name: "Togo",
          country: "Togo",
          full_name: "Togo",
      },
  },
  {
      jurisdiction: {
          code: "th",
          name: "Thailand",
          country: "Thailand",
          full_name: "Thailand",
      },
  },
  {
      jurisdiction: {
          code: "tj",
          name: "Tajikistan",
          country: "Tajikistan",
          full_name: "Tajikistan",
      },
  },
  {
      jurisdiction: {
          code: "tk",
          name: "Tokelau",
          country: "Tokelau",
          full_name: "Tokelau",
      },
  },
  {
      jurisdiction: {
          code: "tl",
          name: "Timor-Leste",
          country: "Timor-Leste",
          full_name: "Timor-Leste",
      },
  },
  {
      jurisdiction: {
          code: "tm",
          name: "Turkmenistan",
          country: "Turkmenistan",
          full_name: "Turkmenistan",
      },
  },
  {
      jurisdiction: {
          code: "tn",
          name: "Tunisia",
          country: "Tunisia",
          full_name: "Tunisia",
      },
  },
  {
      jurisdiction: {
          code: "to",
          name: "Tonga",
          country: "Tonga",
          full_name: "Tonga",
      },
  },
  {
      jurisdiction: {
          code: "tr",
          name: "Turkey",
          country: "Turkey",
          full_name: "Turkey",
      },
  },
  {
      jurisdiction: {
          code: "tt",
          name: "Trinidad and Tobago",
          country: "Trinidad and Tobago",
          full_name: "Trinidad and Tobago",
      },
  },
  {
      jurisdiction: {
          code: "tv",
          name: "Tuvalu",
          country: "Tuvalu",
          full_name: "Tuvalu",
      },
  },
  {
      jurisdiction: {
          code: "tw",
          name: "Taiwan, Province of China",
          country: "Taiwan, Province of China",
          full_name: "Taiwan, Province of China",
      },
  },
  {
      jurisdiction: {
          code: "tz",
          name: "Tanzania",
          country: "Tanzania",
          full_name: "Tanzania",
      },
  },
  {
      jurisdiction: {
          code: "ua",
          name: "Ukraine",
          country: "Ukraine",
          full_name: "Ukraine",
      },
  },
  {
      jurisdiction: {
          code: "ug",
          name: "Uganda",
          country: "Uganda",
          full_name: "Uganda",
      },
  },
  {
      jurisdiction: {
          code: "um",
          name: "United States Minor Outlying Islands",
          country: "United States Minor Outlying Islands",
          full_name: "United States Minor Outlying Islands",
      },
  },
  {
      jurisdiction: {
          code: "us",
          name: "United States",
          country: "United States",
          full_name: "United States",
      },
  },
  {
      jurisdiction: {
          code: "us_ak",
          name: "Alaska",
          country: "United States",
          full_name: "Alaska (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_al",
          name: "Alabama",
          country: "United States",
          full_name: "Alabama (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ar",
          name: "Arkansas",
          country: "United States",
          full_name: "Arkansas (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_az",
          name: "Arizona",
          country: "United States",
          full_name: "Arizona (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ca",
          name: "California",
          country: "United States",
          full_name: "California (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_co",
          name: "Colorado",
          country: "United States",
          full_name: "Colorado (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ct",
          name: "Connecticut",
          country: "United States",
          full_name: "Connecticut (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_dc",
          name: "District of Columbia",
          country: "United States",
          full_name: "District of Columbia (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_de",
          name: "Delaware",
          country: "United States",
          full_name: "Delaware (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_fl",
          name: "Florida",
          country: "United States",
          full_name: "Florida (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ga",
          name: "Georgia",
          country: "United States",
          full_name: "Georgia (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_hi",
          name: "Hawaii",
          country: "United States",
          full_name: "Hawaii (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ia",
          name: "Iowa",
          country: "United States",
          full_name: "Iowa (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_id",
          name: "Idaho",
          country: "United States",
          full_name: "Idaho (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_il",
          name: "Illinois",
          country: "United States",
          full_name: "Illinois (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_in",
          name: "Indiana",
          country: "United States",
          full_name: "Indiana (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ks",
          name: "Kansas",
          country: "United States",
          full_name: "Kansas (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ky",
          name: "Kentucky",
          country: "United States",
          full_name: "Kentucky (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_la",
          name: "Louisiana",
          country: "United States",
          full_name: "Louisiana (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ma",
          name: "Massachusetts",
          country: "United States",
          full_name: "Massachusetts (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_md",
          name: "Maryland",
          country: "United States",
          full_name: "Maryland (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_me",
          name: "Maine",
          country: "United States",
          full_name: "Maine (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_mi",
          name: "Michigan",
          country: "United States",
          full_name: "Michigan (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_mn",
          name: "Minnesota",
          country: "United States",
          full_name: "Minnesota (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_mo",
          name: "Missouri",
          country: "United States",
          full_name: "Missouri (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ms",
          name: "Mississippi",
          country: "United States",
          full_name: "Mississippi (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_mt",
          name: "Montana",
          country: "United States",
          full_name: "Montana (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_nc",
          name: "North Carolina",
          country: "United States",
          full_name: "North Carolina (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_nd",
          name: "North Dakota",
          country: "United States",
          full_name: "North Dakota (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ne",
          name: "Nebraska",
          country: "United States",
          full_name: "Nebraska (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_nh",
          name: "New Hampshire",
          country: "United States",
          full_name: "New Hampshire (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_nj",
          name: "New Jersey",
          country: "United States",
          full_name: "New Jersey (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_nm",
          name: "New Mexico",
          country: "United States",
          full_name: "New Mexico (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_nv",
          name: "Nevada",
          country: "United States",
          full_name: "Nevada (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ny",
          name: "New York",
          country: "United States",
          full_name: "New York (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_oh",
          name: "Ohio",
          country: "United States",
          full_name: "Ohio (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ok",
          name: "Oklahoma",
          country: "United States",
          full_name: "Oklahoma (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_or",
          name: "Oregon",
          country: "United States",
          full_name: "Oregon (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_pa",
          name: "Pennsylvania",
          country: "United States",
          full_name: "Pennsylvania (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ri",
          name: "Rhode Island",
          country: "United States",
          full_name: "Rhode Island (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_sc",
          name: "South Carolina",
          country: "United States",
          full_name: "South Carolina (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_sd",
          name: "South Dakota",
          country: "United States",
          full_name: "South Dakota (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_tn",
          name: "Tennessee",
          country: "United States",
          full_name: "Tennessee (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_tx",
          name: "Texas",
          country: "United States",
          full_name: "Texas (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_ut",
          name: "Utah",
          country: "United States",
          full_name: "Utah (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_va",
          name: "Virginia",
          country: "United States",
          full_name: "Virginia (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_vt",
          name: "Vermont",
          country: "United States",
          full_name: "Vermont (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_wa",
          name: "Washington",
          country: "United States",
          full_name: "Washington (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_wi",
          name: "Wisconsin",
          country: "United States",
          full_name: "Wisconsin (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_wv",
          name: "West Virginia",
          country: "United States",
          full_name: "West Virginia (United States)",
      },
  },
  {
      jurisdiction: {
          code: "us_wy",
          name: "Wyoming",
          country: "United States",
          full_name: "Wyoming (United States)",
      },
  },
  {
      jurisdiction: {
          code: "uy",
          name: "Uruguay",
          country: "Uruguay",
          full_name: "Uruguay",
      },
  },
  {
      jurisdiction: {
          code: "uz",
          name: "Uzbekistan",
          country: "Uzbekistan",
          full_name: "Uzbekistan",
      },
  },
  {
      jurisdiction: {
          code: "va",
          name: "Holy See (Vatican City State)",
          country: "Holy See (Vatican City State)",
          full_name: "Holy See (Vatican City State)",
      },
  },
  {
      jurisdiction: {
          code: "vc",
          name: "Saint Vincent and the Grenadines",
          country: "Saint Vincent and the Grenadines",
          full_name: "Saint Vincent and the Grenadines",
      },
  },
  {
      jurisdiction: {
          code: "ve",
          name: "Venezuela",
          country: "Venezuela",
          full_name: "Venezuela",
      },
  },
  {
      jurisdiction: {
          code: "vg",
          name: "British Virgin Islands",
          country: "British Virgin Islands",
          full_name: "British Virgin Islands",
      },
  },
  {
      jurisdiction: {
          code: "vi",
          name: "U.S. Virgin Islands",
          country: "U.S. Virgin Islands",
          full_name: "U.S. Virgin Islands",
      },
  },
  {
      jurisdiction: {
          code: "vn",
          name: "Viet Nam",
          country: "Viet Nam",
          full_name: "Viet Nam",
      },
  },
  {
      jurisdiction: {
          code: "vu",
          name: "Vanuatu",
          country: "Vanuatu",
          full_name: "Vanuatu",
      },
  },
  {
      jurisdiction: {
          code: "wf",
          name: "Wallis and Futuna",
          country: "Wallis and Futuna",
          full_name: "Wallis and Futuna",
      },
  },
  {
      jurisdiction: {
          code: "ws",
          name: "Samoa",
          country: "Samoa",
          full_name: "Samoa",
      },
  },
  {
      jurisdiction: {
          code: "xk",
          name: "Kosovo",
          country: "Kosovo",
          full_name: "Kosovo",
      },
  },
  {
      jurisdiction: {
          code: "ye",
          name: "Yemen",
          country: "Yemen",
          full_name: "Yemen",
      },
  },
  {
      jurisdiction: {
          code: "yt",
          name: "Mayotte",
          country: "Mayotte",
          full_name: "Mayotte",
      },
  },
  {
      jurisdiction: {
          code: "za",
          name: "South Africa",
          country: "South Africa",
          full_name: "South Africa",
      },
  },
  {
      jurisdiction: {
          code: "zm",
          name: "Zambia",
          country: "Zambia",
          full_name: "Zambia",
      },
  },
  {
      jurisdiction: {
          code: "zw",
          name: "Zimbabwe",
          country: "Zimbabwe",
          full_name: "Zimbabwe",
      },
  },
  {
      jurisdiction: {
          code: "cn_34",
          name: "Anhui",
          country: "China",
          full_name: "Anhui (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_11",
          name: "Beijing",
          country: "China",
          full_name: "Beijing (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_50",
          name: "Chongqing",
          country: "China",
          full_name: "Chongqing (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_35",
          name: "Fujian",
          country: "China",
          full_name: "Fujian (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_62",
          name: "Gansu",
          country: "China",
          full_name: "Gansu (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_44",
          name: "Guangdong",
          country: "China",
          full_name: "Guangdong (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_45",
          name: "Guangxi",
          country: "China",
          full_name: "Guangxi (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_52",
          name: "Guizhou",
          country: "China",
          full_name: "Guizhou (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_46",
          name: "Hainan",
          country: "China",
          full_name: "Hainan (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_13",
          name: "Hebei",
          country: "China",
          full_name: "Hebei (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_23",
          name: "Heilongjiang",
          country: "China",
          full_name: "Heilongjiang (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_41",
          name: "Henan",
          country: "China",
          full_name: "Henan (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_42",
          name: "Hubei",
          country: "China",
          full_name: "Hubei (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_43",
          name: "Hunan",
          country: "China",
          full_name: "Hunan (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_15",
          name: "Inner Mongolia",
          country: "China",
          full_name: "Inner Mongolia (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_32",
          name: "Jiangsu",
          country: "China",
          full_name: "Jiangsu (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_36",
          name: "Jiangxi",
          country: "China",
          full_name: "Jiangxi (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_22",
          name: "Jilin",
          country: "China",
          full_name: "Jilin (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_21",
          name: "Liaoning",
          country: "China",
          full_name: "Liaoning (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_92",
          name: "Macau",
          country: "China",
          full_name: "Macau (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_64",
          name: "Ningxia",
          country: "China",
          full_name: "Ningxia (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_63",
          name: "Qinghai",
          country: "China",
          full_name: "Qinghai (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_61",
          name: "Shaanxi",
          country: "China",
          full_name: "Shaanxi (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_37",
          name: "Shandong",
          country: "China",
          full_name: "Shandong (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_31",
          name: "Shanghai",
          country: "China",
          full_name: "Shanghai (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_14",
          name: "Shanxi",
          country: "China",
          full_name: "Shanxi (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_51",
          name: "Sichuan",
          country: "China",
          full_name: "Sichuan (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_12",
          name: "Tianjin",
          country: "China",
          full_name: "Tianjin (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_54",
          name: "Tibet",
          country: "China",
          full_name: "Tibet (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_65",
          name: "Xinjiang",
          country: "China",
          full_name: "Xinjiang (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_53",
          name: "Yunnan",
          country: "China",
          full_name: "Yunnan (China)",
      },
  },
  {
      jurisdiction: {
          code: "cn_33",
          name: "Zhejiang",
          country: "China",
          full_name: "Zhejiang (China)",
      },
  },
  {
      jurisdiction: {
          code: "ae_aj",
          name: "Ajman",
          country: "United Arab Emirates",
          full_name: "Ajman (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "ae_fu",
          name: "Fujairah",
          country: "United Arab Emirates",
          full_name: "Fujairah (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "ae_rk",
          name: "Ras Al-khaimah",
          country: "United Arab Emirates",
          full_name: "Ras Al-khaimah (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "ae_sh",
          name: "Sharjah",
          country: "United Arab Emirates",
          full_name: "Sharjah (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "ae_uq",
          name: "Umm Al-quwain",
          country: "United Arab Emirates",
          full_name: "Umm Al-quwain (United Arab Emirates)",
      },
  },
  {
      jurisdiction: {
          code: "afg",
          name: "Afghanistan",
          country: "Afghanistan",
          full_name: "Afghanistan",
      },
  },
  {
      jurisdiction: {
          code: "alb",
          name: "Albania",
          country: "Albania",
          full_name: "Albania",
      },
  },
  {
      jurisdiction: {
          code: "dza",
          name: "Algeria",
          country: "Algeria",
          full_name: "Algeria",
      },
  },
  {
      jurisdiction: {
          code: "asm",
          name: "American Samoa",
          country: "American Samoa",
          full_name: "American Samoa",
      },
  },
  {
      jurisdiction: {
          code: "and",
          name: "Andorra",
          country: "Andorra",
          full_name: "Andorra",
      },
  },
  {
      jurisdiction: {
          code: "ago",
          name: "Angola",
          country: "Angola",
          full_name: "Angola",
      },
  },
  {
      jurisdiction: {
          code: "aia",
          name: "Anguilla",
          country: "Anguilla",
          full_name: "Anguilla",
      },
  },
  {
      jurisdiction: {
          code: "atg",
          name: "Antigua And Barbuda",
          country: "Antigua And Barbuda",
          full_name: "Antigua And Barbuda",
      },
  },
  {
      jurisdiction: {
          code: "arg",
          name: "Argentina",
          country: "Argentina",
          full_name: "Argentina",
      },
  },
  {
      jurisdiction: {
          code: "arm",
          name: "Armenia",
          country: "Armenia",
          full_name: "Armenia",
      },
  },
  {
      jurisdiction: {
          code: "abw",
          name: "Aruba",
          country: "Aruba",
          full_name: "Aruba",
      },
  },
  {
      jurisdiction: {
          code: "aus",
          name: "Australia",
          country: "Australia",
          full_name: "Australia",
      },
  },
  {
      jurisdiction: {
          code: "aut",
          name: "Austria",
          country: "Austria",
          full_name: "Austria",
      },
  },
  {
      jurisdiction: {
          code: "aze",
          name: "Azerbaijan",
          country: "Azerbaijan",
          full_name: "Azerbaijan",
      },
  },
  {
      jurisdiction: {
          code: "bhs",
          name: "Bahamas",
          country: "Bahamas",
          full_name: "Bahamas",
      },
  },
  {
      jurisdiction: {
          code: "bhr",
          name: "Bahrain",
          country: "Bahrain",
          full_name: "Bahrain",
      },
  },
  {
      jurisdiction: {
          code: "bgd",
          name: "Bangladesh",
          country: "Bangladesh",
          full_name: "Bangladesh",
      },
  },
  {
      jurisdiction: {
          code: "brb",
          name: "Barbados",
          country: "Barbados",
          full_name: "Barbados",
      },
  },
  {
      jurisdiction: {
          code: "blr",
          name: "Belarus",
          country: "Belarus",
          full_name: "Belarus",
      },
  },
  {
      jurisdiction: {
          code: "bel",
          name: "Belgium",
          country: "Belgium",
          full_name: "Belgium",
      },
  },
  {
      jurisdiction: {
          code: "blz",
          name: "Belize",
          country: "Belize",
          full_name: "Belize",
      },
  },
  {
      jurisdiction: {
          code: "ben",
          name: "Benin",
          country: "Benin",
          full_name: "Benin",
      },
  },
  {
      jurisdiction: {
          code: "bmu",
          name: "Bermuda",
          country: "Bermuda",
          full_name: "Bermuda",
      },
  },
  {
      jurisdiction: {
          code: "btn",
          name: "Bhutan",
          country: "Bhutan",
          full_name: "Bhutan",
      },
  },
  {
      jurisdiction: {
          code: "bol",
          name: "Bolivia",
          country: "Bolivia",
          full_name: "Bolivia",
      },
  },
  {
      jurisdiction: {
          code: "bih",
          name: "Bosnia And Herzegovina",
          country: "Bosnia And Herzegovina",
          full_name: "Bosnia And Herzegovina",
      },
  },
  {
      jurisdiction: {
          code: "bwa",
          name: "Botswana",
          country: "Botswana",
          full_name: "Botswana",
      },
  },
  {
      jurisdiction: {
          code: "bra",
          name: "Brazil",
          country: "Brazil",
          full_name: "Brazil",
      },
  },
  {
      jurisdiction: {
          code: "brn",
          name: "Brunei Darussalam",
          country: "Brunei Darussalam",
          full_name: "Brunei Darussalam",
      },
  },
  {
      jurisdiction: {
          code: "bgr",
          name: "Bulgaria",
          country: "Bulgaria",
          full_name: "Bulgaria",
      },
  },
  {
      jurisdiction: {
          code: "bfa",
          name: "Burkina Faso",
          country: "Burkina Faso",
          full_name: "Burkina Faso",
      },
  },
  {
      jurisdiction: {
          code: "bdi",
          name: "Burundi",
          country: "Burundi",
          full_name: "Burundi",
      },
  },
  {
      jurisdiction: {
          code: "civ",
          name: "C?te D'ivoire",
          country: "C?te D'ivoire",
          full_name: "C?te D'ivoire",
      },
  },
  {
      jurisdiction: {
          code: "khm",
          name: "Cambodia",
          country: "Cambodia",
          full_name: "Cambodia",
      },
  },
  {
      jurisdiction: {
          code: "cmr",
          name: "Cameroon",
          country: "Cameroon",
          full_name: "Cameroon",
      },
  },
  {
      jurisdiction: {
          code: "can",
          name: "Canada",
          country: "Canada",
          full_name: "Canada",
      },
  },
  {
      jurisdiction: {
          code: "cpv",
          name: "Cape Verde",
          country: "Cape Verde",
          full_name: "Cape Verde",
      },
  },
  {
      jurisdiction: {
          code: "cym",
          name: "Cayman Islands",
          country: "Cayman Islands",
          full_name: "Cayman Islands",
      },
  },
  {
      jurisdiction: {
          code: "caf",
          name: "Central African Republic",
          country: "Central African Republic",
          full_name: "Central African Republic",
      },
  },
  {
      jurisdiction: {
          code: "tcd",
          name: "Chad",
          country: "Chad",
          full_name: "Chad",
      },
  },
  {
      jurisdiction: {
          code: "chl",
          name: "Chile",
          country: "Chile",
          full_name: "Chile",
      },
  },
  {
      jurisdiction: {
          code: "chn",
          name: "China",
          country: "China",
          full_name: "China",
      },
  },
  {
      jurisdiction: {
          code: "col",
          name: "Colombia",
          country: "Colombia",
          full_name: "Colombia",
      },
  },
  {
      jurisdiction: {
          code: "com",
          name: "Comoros",
          country: "Comoros",
          full_name: "Comoros",
      },
  },
  {
      jurisdiction: {
          code: "cog",
          name: "Congo",
          country: "Congo",
          full_name: "Congo",
      },
  },
  {
      jurisdiction: {
          code: "cod",
          name: "Congo, Democratic Republic Of The",
          country: "Congo, Democratic Republic Of The",
          full_name: "Congo, Democratic Republic Of The",
      },
  },
  {
      jurisdiction: {
          code: "cok",
          name: "Cook Islands",
          country: "Cook Islands",
          full_name: "Cook Islands",
      },
  },
  {
      jurisdiction: {
          code: "cri",
          name: "Costa Rica",
          country: "Costa Rica",
          full_name: "Costa Rica",
      },
  },
  {
      jurisdiction: {
          code: "hrv",
          name: "Croatia",
          country: "Croatia",
          full_name: "Croatia",
      },
  },
  {
      jurisdiction: {
          code: "cub",
          name: "Cuba",
          country: "Cuba",
          full_name: "Cuba",
      },
  },
  {
      jurisdiction: {
          code: "cyp",
          name: "Cyprus",
          country: "Cyprus",
          full_name: "Cyprus",
      },
  },
  {
      jurisdiction: {
          code: "cze",
          name: "Czech Republic",
          country: "Czech Republic",
          full_name: "Czech Republic",
      },
  },
  {
      jurisdiction: {
          code: "dnk",
          name: "Denmark",
          country: "Denmark",
          full_name: "Denmark",
      },
  },
  {
      jurisdiction: {
          code: "dji",
          name: "Djibouti",
          country: "Djibouti",
          full_name: "Djibouti",
      },
  },
  {
      jurisdiction: {
          code: "dma",
          name: "Dominica",
          country: "Dominica",
          full_name: "Dominica",
      },
  },
  {
      jurisdiction: {
          code: "dom",
          name: "Dominican Republic",
          country: "Dominican Republic",
          full_name: "Dominican Republic",
      },
  },
  {
      jurisdiction: {
          code: "ecu",
          name: "Ecuador",
          country: "Ecuador",
          full_name: "Ecuador",
      },
  },
  {
      jurisdiction: {
          code: "egy",
          name: "Egypt",
          country: "Egypt",
          full_name: "Egypt",
      },
  },
  {
      jurisdiction: {
          code: "slv",
          name: "El Salvador",
          country: "El Salvador",
          full_name: "El Salvador",
      },
  },
  {
      jurisdiction: {
          code: "gnq",
          name: "Equatorial Guinea",
          country: "Equatorial Guinea",
          full_name: "Equatorial Guinea",
      },
  },
  {
      jurisdiction: {
          code: "eri",
          name: "Eritrea",
          country: "Eritrea",
          full_name: "Eritrea",
      },
  },
  {
      jurisdiction: {
          code: "est",
          name: "Estonia",
          country: "Estonia",
          full_name: "Estonia",
      },
  },
  {
      jurisdiction: {
          code: "eth",
          name: "Ethiopia",
          country: "Ethiopia",
          full_name: "Ethiopia",
      },
  },
  {
      jurisdiction: {
          code: "flk",
          name: "Falkland Islands",
          country: "Falkland Islands",
          full_name: "Falkland Islands",
      },
  },
  {
      jurisdiction: {
          code: "fro",
          name: "Faroe Islands",
          country: "Faroe Islands",
          full_name: "Faroe Islands",
      },
  },
  {
      jurisdiction: {
          code: "fji",
          name: "Fiji",
          country: "Fiji",
          full_name: "Fiji",
      },
  },
  {
      jurisdiction: {
          code: "fin",
          name: "Finland",
          country: "Finland",
          full_name: "Finland",
      },
  },
  {
      jurisdiction: {
          code: "fra",
          name: "France",
          country: "France",
          full_name: "France",
      },
  },
  {
      jurisdiction: {
          code: "guf",
          name: "French Guiana",
          country: "French Guiana",
          full_name: "French Guiana",
      },
  },
  {
      jurisdiction: {
          code: "pyf",
          name: "French Polynesia",
          country: "French Polynesia",
          full_name: "French Polynesia",
      },
  },
  {
      jurisdiction: {
          code: "gab",
          name: "Gabon",
          country: "Gabon",
          full_name: "Gabon",
      },
  },
  {
      jurisdiction: {
          code: "gmb",
          name: "Gambia",
          country: "Gambia",
          full_name: "Gambia",
      },
  },
  {
      jurisdiction: {
          code: "gaz",
          name: "Gaza Strip",
          country: "Gaza Strip",
          full_name: "Gaza Strip",
      },
  },
  {
      jurisdiction: {
          code: "geo",
          name: "Georgia",
          country: "Georgia",
          full_name: "Georgia",
      },
  },
  {
      jurisdiction: {
          code: "deu",
          name: "Germany",
          country: "Germany",
          full_name: "Germany",
      },
  },
  {
      jurisdiction: {
          code: "gha",
          name: "Ghana",
          country: "Ghana",
          full_name: "Ghana",
      },
  },
  {
      jurisdiction: {
          code: "gib",
          name: "Gibraltar",
          country: "Gibraltar",
          full_name: "Gibraltar",
      },
  },
  {
      jurisdiction: {
          code: "gol",
          name: "Golan Heights",
          country: "Golan Heights",
          full_name: "Golan Heights",
      },
  },
  {
      jurisdiction: {
          code: "grc",
          name: "Greece",
          country: "Greece",
          full_name: "Greece",
      },
  },
  {
      jurisdiction: {
          code: "grl",
          name: "Greenland",
          country: "Greenland",
          full_name: "Greenland",
      },
  },
  {
      jurisdiction: {
          code: "grd",
          name: "Grenada",
          country: "Grenada",
          full_name: "Grenada",
      },
  },
  {
      jurisdiction: {
          code: "glp",
          name: "Guadeloupe",
          country: "Guadeloupe",
          full_name: "Guadeloupe",
      },
  },
  {
      jurisdiction: {
          code: "gum",
          name: "Guam",
          country: "Guam",
          full_name: "Guam",
      },
  },
  {
      jurisdiction: {
          code: "gtm",
          name: "Guatemala",
          country: "Guatemala",
          full_name: "Guatemala",
      },
  },
  {
      jurisdiction: {
          code: "gin",
          name: "Guinea",
          country: "Guinea",
          full_name: "Guinea",
      },
  },
  {
      jurisdiction: {
          code: "gnb",
          name: "Guinea-bissau",
          country: "Guinea-bissau",
          full_name: "Guinea-bissau",
      },
  },
  {
      jurisdiction: {
          code: "guy",
          name: "Guyana",
          country: "Guyana",
          full_name: "Guyana",
      },
  },
  {
      jurisdiction: {
          code: "hti",
          name: "Haiti",
          country: "Haiti",
          full_name: "Haiti",
      },
  },
  {
      jurisdiction: {
          code: "hnd",
          name: "Honduras",
          country: "Honduras",
          full_name: "Honduras",
      },
  },
  {
      jurisdiction: {
          code: "hkg",
          name: "Hong Kong",
          country: "Hong Kong",
          full_name: "Hong Kong",
      },
  },
  {
      jurisdiction: {
          code: "hun",
          name: "Hungary",
          country: "Hungary",
          full_name: "Hungary",
      },
  },
  {
      jurisdiction: {
          code: "isl",
          name: "Iceland",
          country: "Iceland",
          full_name: "Iceland",
      },
  },
  {
      jurisdiction: {
          code: "ind",
          name: "India",
          country: "India",
          full_name: "India",
      },
  },
  {
      jurisdiction: {
          code: "idn",
          name: "Indonesia",
          country: "Indonesia",
          full_name: "Indonesia",
      },
  },
  {
      jurisdiction: {
          code: "irn",
          name: "Iran",
          country: "Iran",
          full_name: "Iran",
      },
  },
  {
      jurisdiction: {
          code: "irq",
          name: "Iraq",
          country: "Iraq",
          full_name: "Iraq",
      },
  },
  {
      jurisdiction: {
          code: "irl",
          name: "Ireland",
          country: "Ireland",
          full_name: "Ireland",
      },
  },
  {
      jurisdiction: {
          code: "isr",
          name: "Israel",
          country: "Israel",
          full_name: "Israel",
      },
  },
  {
      jurisdiction: {
          code: "ita",
          name: "Italy",
          country: "Italy",
          full_name: "Italy",
      },
  },
  {
      jurisdiction: {
          code: "jam",
          name: "Jamaica",
          country: "Jamaica",
          full_name: "Jamaica",
      },
  },
  {
      jurisdiction: {
          code: "jpn",
          name: "Japan",
          country: "Japan",
          full_name: "Japan",
      },
  },
  {
      jurisdiction: {
          code: "jor",
          name: "Jordan",
          country: "Jordan",
          full_name: "Jordan",
      },
  },
  {
      jurisdiction: {
          code: "kaz",
          name: "Kazakhstan",
          country: "Kazakhstan",
          full_name: "Kazakhstan",
      },
  },
  {
      jurisdiction: {
          code: "ken",
          name: "Kenya",
          country: "Kenya",
          full_name: "Kenya",
      },
  },
  {
      jurisdiction: {
          code: "kir",
          name: "Kiribati",
          country: "Kiribati",
          full_name: "Kiribati",
      },
  },
  {
      jurisdiction: {
          code: "kor",
          name: "Korea",
          country: "Korea",
          full_name: "Korea",
      },
  },
  {
      jurisdiction: {
          code: "prk",
          name: "Korea, Democratic People's Republic Of",
          country: "Korea, Democratic People's Republic Of",
          full_name: "Korea, Democratic People's Republic Of",
      },
  },
  {
      jurisdiction: {
          code: "kwt",
          name: "Kuwait",
          country: "Kuwait",
          full_name: "Kuwait",
      },
  },
  {
      jurisdiction: {
          code: "kgz",
          name: "Kyrgyzstan",
          country: "Kyrgyzstan",
          full_name: "Kyrgyzstan",
      },
  },
  {
      jurisdiction: {
          code: "lao",
          name: "Laos",
          country: "Laos",
          full_name: "Laos",
      },
  },
  {
      jurisdiction: {
          code: "lva",
          name: "Latvia",
          country: "Latvia",
          full_name: "Latvia",
      },
  },
  {
      jurisdiction: {
          code: "lbn",
          name: "Lebanon",
          country: "Lebanon",
          full_name: "Lebanon",
      },
  },
  {
      jurisdiction: {
          code: "lso",
          name: "Lesotho",
          country: "Lesotho",
          full_name: "Lesotho",
      },
  },
  {
      jurisdiction: {
          code: "lbr",
          name: "Liberia",
          country: "Liberia",
          full_name: "Liberia",
      },
  },
  {
      jurisdiction: {
          code: "lby",
          name: "Libya",
          country: "Libya",
          full_name: "Libya",
      },
  },
  {
      jurisdiction: {
          code: "lie",
          name: "Liechtenstein",
          country: "Liechtenstein",
          full_name: "Liechtenstein",
      },
  },
  {
      jurisdiction: {
          code: "ltu",
          name: "Lithuania",
          country: "Lithuania",
          full_name: "Lithuania",
      },
  },
  {
      jurisdiction: {
          code: "lux",
          name: "Luxembourg",
          country: "Luxembourg",
          full_name: "Luxembourg",
      },
  },
  {
      jurisdiction: {
          code: "mac",
          name: "Macau",
          country: "Macau",
          full_name: "Macau",
      },
  },
  {
      jurisdiction: {
          code: "mkd",
          name: "Macedonia",
          country: "Macedonia",
          full_name: "Macedonia",
      },
  },
  {
      jurisdiction: {
          code: "mdg",
          name: "Madagascar",
          country: "Madagascar",
          full_name: "Madagascar",
      },
  },
  {
      jurisdiction: {
          code: "mwi",
          name: "Malawi",
          country: "Malawi",
          full_name: "Malawi",
      },
  },
  {
      jurisdiction: {
          code: "mys",
          name: "Malaysia",
          country: "Malaysia",
          full_name: "Malaysia",
      },
  },
  {
      jurisdiction: {
          code: "mdv",
          name: "Maldives",
          country: "Maldives",
          full_name: "Maldives",
      },
  },
  {
      jurisdiction: {
          code: "mli",
          name: "Mali",
          country: "Mali",
          full_name: "Mali",
      },
  },
  {
      jurisdiction: {
          code: "mlt",
          name: "Malta",
          country: "Malta",
          full_name: "Malta",
      },
  },
  {
      jurisdiction: {
          code: "mhl",
          name: "Marshall Islands",
          country: "Marshall Islands",
          full_name: "Marshall Islands",
      },
  },
  {
      jurisdiction: {
          code: "mtq",
          name: "Martinique",
          country: "Martinique",
          full_name: "Martinique",
      },
  },
  {
      jurisdiction: {
          code: "mrt",
          name: "Mauritania",
          country: "Mauritania",
          full_name: "Mauritania",
      },
  },
  {
      jurisdiction: {
          code: "mus",
          name: "Mauritius",
          country: "Mauritius",
          full_name: "Mauritius",
      },
  },
  {
      jurisdiction: {
          code: "myt",
          name: "Mayotte",
          country: "Mayotte",
          full_name: "Mayotte",
      },
  },
  {
      jurisdiction: {
          code: "mex",
          name: "Mexico",
          country: "Mexico",
          full_name: "Mexico",
      },
  },
  {
      jurisdiction: {
          code: "fsm",
          name: "Micronesia",
          country: "Micronesia",
          full_name: "Micronesia",
      },
  },
  {
      jurisdiction: {
          code: "mda",
          name: "Moldova",
          country: "Moldova",
          full_name: "Moldova",
      },
  },
  {
      jurisdiction: {
          code: "mco",
          name: "Monaco",
          country: "Monaco",
          full_name: "Monaco",
      },
  },
  {
      jurisdiction: {
          code: "mng",
          name: "Mongolia",
          country: "Mongolia",
          full_name: "Mongolia",
      },
  },
  {
      jurisdiction: {
          code: "mne",
          name: "Montenegro",
          country: "Montenegro",
          full_name: "Montenegro",
      },
  },
  {
      jurisdiction: {
          code: "msr",
          name: "Montserrat",
          country: "Montserrat",
          full_name: "Montserrat",
      },
  },
  {
      jurisdiction: {
          code: "mar",
          name: "Morocco",
          country: "Morocco",
          full_name: "Morocco",
      },
  },
  {
      jurisdiction: {
          code: "moz",
          name: "Mozambique",
          country: "Mozambique",
          full_name: "Mozambique",
      },
  },
  {
      jurisdiction: {
          code: "mmr",
          name: "Myanmar",
          country: "Myanmar",
          full_name: "Myanmar",
      },
  },
  {
      jurisdiction: {
          code: "nam",
          name: "Namibia",
          country: "Namibia",
          full_name: "Namibia",
      },
  },
  {
      jurisdiction: {
          code: "nru",
          name: "Nauru",
          country: "Nauru",
          full_name: "Nauru",
      },
  },
  {
      jurisdiction: {
          code: "npl",
          name: "Nepal",
          country: "Nepal",
          full_name: "Nepal",
      },
  },
  {
      jurisdiction: {
          code: "nld",
          name: "Netherlands",
          country: "Netherlands",
          full_name: "Netherlands",
      },
  },
  {
      jurisdiction: {
          code: "ant",
          name: "Netherlands Antilles",
          country: "Netherlands Antilles",
          full_name: "Netherlands Antilles",
      },
  },
  {
      jurisdiction: {
          code: "ncl",
          name: "New Caledonia",
          country: "New Caledonia",
          full_name: "New Caledonia",
      },
  },
  {
      jurisdiction: {
          code: "nzl",
          name: "New Zealand",
          country: "New Zealand",
          full_name: "New Zealand",
      },
  },
  {
      jurisdiction: {
          code: "nic",
          name: "Nicaragua",
          country: "Nicaragua",
          full_name: "Nicaragua",
      },
  },
  {
      jurisdiction: {
          code: "ner",
          name: "Niger",
          country: "Niger",
          full_name: "Niger",
      },
  },
  {
      jurisdiction: {
          code: "nga",
          name: "Nigeria",
          country: "Nigeria",
          full_name: "Nigeria",
      },
  },
  {
      jurisdiction: {
          code: "niu",
          name: "Niue",
          country: "Niue",
          full_name: "Niue",
      },
  },
  {
      jurisdiction: {
          code: "nfk",
          name: "Norfolk Island",
          country: "Norfolk Island",
          full_name: "Norfolk Island",
      },
  },
  {
      jurisdiction: {
          code: "mnp",
          name: "Northern Mariana Islands",
          country: "Northern Mariana Islands",
          full_name: "Northern Mariana Islands",
      },
  },
  {
      jurisdiction: {
          code: "nor",
          name: "Norway",
          country: "Norway",
          full_name: "Norway",
      },
  },
  {
      jurisdiction: {
          code: "omn",
          name: "Oman",
          country: "Oman",
          full_name: "Oman",
      },
  },
  {
      jurisdiction: {
          code: "pak",
          name: "Pakistan",
          country: "Pakistan",
          full_name: "Pakistan",
      },
  },
  {
      jurisdiction: {
          code: "plw",
          name: "Palau",
          country: "Palau",
          full_name: "Palau",
      },
  },
  {
      jurisdiction: {
          code: "pan",
          name: "Panama",
          country: "Panama",
          full_name: "Panama",
      },
  },
  {
      jurisdiction: {
          code: "png",
          name: "Papua New Guinea",
          country: "Papua New Guinea",
          full_name: "Papua New Guinea",
      },
  },
  {
      jurisdiction: {
          code: "pry",
          name: "Paraguay",
          country: "Paraguay",
          full_name: "Paraguay",
      },
  },
  {
      jurisdiction: {
          code: "per",
          name: "Peru",
          country: "Peru",
          full_name: "Peru",
      },
  },
  {
      jurisdiction: {
          code: "phl",
          name: "Philippines",
          country: "Philippines",
          full_name: "Philippines",
      },
  },
  {
      jurisdiction: {
          code: "pol",
          name: "Poland",
          country: "Poland",
          full_name: "Poland",
      },
  },
  {
      jurisdiction: {
          code: "prt",
          name: "Portugal",
          country: "Portugal",
          full_name: "Portugal",
      },
  },
  {
      jurisdiction: {
          code: "pri",
          name: "Puerto Rico",
          country: "Puerto Rico",
          full_name: "Puerto Rico",
      },
  },
  {
      jurisdiction: {
          code: "qat",
          name: "Qatar",
          country: "Qatar",
          full_name: "Qatar",
      },
  },
  {
      jurisdiction: {
          code: "rou",
          name: "Romania",
          country: "Romania",
          full_name: "Romania",
      },
  },
  {
      jurisdiction: {
          code: "rus",
          name: "Russia",
          country: "Russia",
          full_name: "Russia",
      },
  },
  {
      jurisdiction: {
          code: "rwa",
          name: "Rwanda",
          country: "Rwanda",
          full_name: "Rwanda",
      },
  },
  {
      jurisdiction: {
          code: "reu",
          name: "Rï¿½ï¿½union",
          country: "Rï¿½ï¿½union",
          full_name: "Rï¿½ï¿½union",
      },
  },
  {
      jurisdiction: {
          code: "shn",
          name: "Saint Helena",
          country: "Saint Helena",
          full_name: "Saint Helena",
      },
  },
  {
      jurisdiction: {
          code: "kna",
          name: "Saint Kitts And Nevis",
          country: "Saint Kitts And Nevis",
          full_name: "Saint Kitts And Nevis",
      },
  },
  {
      jurisdiction: {
          code: "lca",
          name: "Saint Lucia",
          country: "Saint Lucia",
          full_name: "Saint Lucia",
      },
  },
  {
      jurisdiction: {
          code: "spm",
          name: "Saint Pierre And Miquelon",
          country: "Saint Pierre And Miquelon",
          full_name: "Saint Pierre And Miquelon",
      },
  },
  {
      jurisdiction: {
          code: "vct",
          name: "Saint Vincent And The Grenadines",
          country: "Saint Vincent And The Grenadines",
          full_name: "Saint Vincent And The Grenadines",
      },
  },
  {
      jurisdiction: {
          code: "wsm",
          name: "Samoa",
          country: "Samoa",
          full_name: "Samoa",
      },
  },
  {
      jurisdiction: {
          code: "smr",
          name: "San Marino",
          country: "San Marino",
          full_name: "San Marino",
      },
  },
  {
      jurisdiction: {
          code: "stp",
          name: "Sao Tome And Principe",
          country: "Sao Tome And Principe",
          full_name: "Sao Tome And Principe",
      },
  },
  {
      jurisdiction: {
          code: "sau",
          name: "Saudi Arabia",
          country: "Saudi Arabia",
          full_name: "Saudi Arabia",
      },
  },
  {
      jurisdiction: {
          code: "sen",
          name: "Senegal",
          country: "Senegal",
          full_name: "Senegal",
      },
  },
  {
      jurisdiction: {
          code: "scg",
          name: "Serbia",
          country: "Serbia",
          full_name: "Serbia",
      },
  },
  {
      jurisdiction: {
          code: "syc",
          name: "Seychelles",
          country: "Seychelles",
          full_name: "Seychelles",
      },
  },
  {
      jurisdiction: {
          code: "sle",
          name: "Sierra Leone",
          country: "Sierra Leone",
          full_name: "Sierra Leone",
      },
  },
  {
      jurisdiction: {
          code: "sgp",
          name: "Singapore",
          country: "Singapore",
          full_name: "Singapore",
      },
  },
  {
      jurisdiction: {
          code: "svk",
          name: "Slovakia",
          country: "Slovakia",
          full_name: "Slovakia",
      },
  },
  {
      jurisdiction: {
          code: "svn",
          name: "Slovenia",
          country: "Slovenia",
          full_name: "Slovenia",
      },
  },
  {
      jurisdiction: {
          code: "slb",
          name: "Solomon Islands",
          country: "Solomon Islands",
          full_name: "Solomon Islands",
      },
  },
  {
      jurisdiction: {
          code: "som",
          name: "Somalia",
          country: "Somalia",
          full_name: "Somalia",
      },
  },
  {
      jurisdiction: {
          code: "zaf",
          name: "South Africa",
          country: "South Africa",
          full_name: "South Africa",
      },
  },
  {
      jurisdiction: {
          code: "esp",
          name: "Spain",
          country: "Spain",
          full_name: "Spain",
      },
  },
  {
      jurisdiction: {
          code: "lka",
          name: "Sri Lanka",
          country: "Sri Lanka",
          full_name: "Sri Lanka",
      },
  },
  {
      jurisdiction: {
          code: "sdn",
          name: "Sudan",
          country: "Sudan",
          full_name: "Sudan",
      },
  },
  {
      jurisdiction: {
          code: "sur",
          name: "Suriname",
          country: "Suriname",
          full_name: "Suriname",
      },
  },
  {
      jurisdiction: {
          code: "sjm",
          name: "Svalbard And Jan Mayen",
          country: "Svalbard And Jan Mayen",
          full_name: "Svalbard And Jan Mayen",
      },
  },
  {
      jurisdiction: {
          code: "swz",
          name: "Swaziland",
          country: "Swaziland",
          full_name: "Swaziland",
      },
  },
  {
      jurisdiction: {
          code: "swe",
          name: "Sweden",
          country: "Sweden",
          full_name: "Sweden",
      },
  },
  {
      jurisdiction: {
          code: "che",
          name: "Switzerland",
          country: "Switzerland",
          full_name: "Switzerland",
      },
  },
  {
      jurisdiction: {
          code: "syr",
          name: "Syria",
          country: "Syria",
          full_name: "Syria",
      },
  },
  {
      jurisdiction: {
          code: "twn",
          name: "Taiwan",
          country: "Taiwan",
          full_name: "Taiwan",
      },
  },
  {
      jurisdiction: {
          code: "tjk",
          name: "Tajikistan",
          country: "Tajikistan",
          full_name: "Tajikistan",
      },
  },
  {
      jurisdiction: {
          code: "tza",
          name: "Tanzania",
          country: "Tanzania",
          full_name: "Tanzania",
      },
  },
  {
      jurisdiction: {
          code: "tha",
          name: "Thailand",
          country: "Thailand",
          full_name: "Thailand",
      },
  },
  {
      jurisdiction: {
          code: "tls",
          name: "Timor-leste",
          country: "Timor-leste",
          full_name: "Timor-leste",
      },
  },
  {
      jurisdiction: {
          code: "tgo",
          name: "Togo",
          country: "Togo",
          full_name: "Togo",
      },
  },
  {
      jurisdiction: {
          code: "tkl",
          name: "Tokelau",
          country: "Tokelau",
          full_name: "Tokelau",
      },
  },
  {
      jurisdiction: {
          code: "ton",
          name: "Tonga",
          country: "Tonga",
          full_name: "Tonga",
      },
  },
  {
      jurisdiction: {
          code: "tto",
          name: "Trinidad And Tobago",
          country: "Trinidad And Tobago",
          full_name: "Trinidad And Tobago",
      },
  },
  {
      jurisdiction: {
          code: "tun",
          name: "Tunisia",
          country: "Tunisia",
          full_name: "Tunisia",
      },
  },
  {
      jurisdiction: {
          code: "tur",
          name: "Turkey",
          country: "Turkey",
          full_name: "Turkey",
      },
  },
  {
      jurisdiction: {
          code: "tkm",
          name: "Turkmenistan",
          country: "Turkmenistan",
          full_name: "Turkmenistan",
      },
  },
  {
      jurisdiction: {
          code: "tca",
          name: "Turks And Caicos Islands",
          country: "Turks And Caicos Islands",
          full_name: "Turks And Caicos Islands",
      },
  },
  {
      jurisdiction: {
          code: "tuv",
          name: "Tuvalu",
          country: "Tuvalu",
          full_name: "Tuvalu",
      },
  },
  {
      jurisdiction: {
          code: "uga",
          name: "Uganda",
          country: "Uganda",
          full_name: "Uganda",
      },
  },
  {
      jurisdiction: {
          code: "ukr",
          name: "Ukraine",
          country: "Ukraine",
          full_name: "Ukraine",
      },
  },
  {
      jurisdiction: {
          code: "unn",
          name: "Un Neutral Zone",
          country: "Un Neutral Zone",
          full_name: "Un Neutral Zone",
      },
  },
  {
      jurisdiction: {
          code: "are",
          name: "United Arab Emirates",
          country: "United Arab Emirates",
          full_name: "United Arab Emirates",
      },
  },
  {
      jurisdiction: {
          code: "gbr",
          name: "United Kingdom",
          country: "United Kingdom",
          full_name: "United Kingdom",
      },
  },
  {
      jurisdiction: {
          code: "usa",
          name: "United States",
          country: "United States",
          full_name: "United States",
      },
  },
  {
      jurisdiction: {
          code: "ury",
          name: "Uruguay",
          country: "Uruguay",
          full_name: "Uruguay",
      },
  },
  {
      jurisdiction: {
          code: "uzb",
          name: "Uzbekistan",
          country: "Uzbekistan",
          full_name: "Uzbekistan",
      },
  },
  {
      jurisdiction: {
          code: "vut",
          name: "Vanuatu",
          country: "Vanuatu",
          full_name: "Vanuatu",
      },
  },
  {
      jurisdiction: {
          code: "ven",
          name: "Venezuela",
          country: "Venezuela",
          full_name: "Venezuela",
      },
  },
  {
      jurisdiction: {
          code: "vnm",
          name: "Viet Nam",
          country: "Viet Nam",
          full_name: "Viet Nam",
      },
  },
  {
      jurisdiction: {
          code: "vgb",
          name: "Virgin Islands, British",
          country: "Virgin Islands, British",
          full_name: "Virgin Islands, British",
      },
  },
  {
      jurisdiction: {
          code: "vir",
          name: "Virgin Islands, U.s.",
          country: "Virgin Islands, U.s.",
          full_name: "Virgin Islands, U.s.",
      },
  },
  {
      jurisdiction: {
          code: "wlf",
          name: "Wallis And Futuna",
          country: "Wallis And Futuna",
          full_name: "Wallis And Futuna",
      },
  },
  {
      jurisdiction: {
          code: "wes",
          name: "West Bank",
          country: "West Bank",
          full_name: "West Bank",
      },
  },
  {
      jurisdiction: {
          code: "esh",
          name: "Western Sahara",
          country: "Western Sahara",
          full_name: "Western Sahara",
      },
  },
  {
      jurisdiction: {
          code: "yem",
          name: "Yemen",
          country: "Yemen",
          full_name: "Yemen",
      },
  },
  {
      jurisdiction: {
          code: "zmb",
          name: "Zambia",
          country: "Zambia",
          full_name: "Zambia",
      },
  },
  {
      jurisdiction: {
          code: "zwe",
          name: "Zimbabwe",
          country: "Zimbabwe",
          full_name: "Zimbabwe",
      },
  },

  {
      jurisdiction: {
          code: "af",
          name: "Afghanistan",
          country: "Afghanistan",
          full_name: "Afghanistan",
      },
  },
  {
      jurisdiction: {
          code: "al",
          name: "Albania",
          country: "Albania",
          full_name: "Albania",
      },
  },
  {
      jurisdiction: {
          code: "dz",
          name: "Algeria",
          country: "Algeria",
          full_name: "Algeria",
      },
  },
  {
      jurisdiction: {
          code: "as",
          name: "American Samoa",
          country: "American Samoa",
          full_name: "American Samoa",
      },
  },
  {
      jurisdiction: {
          code: "ad",
          name: "Andorra",
          country: "Andorra",
          full_name: "Andorra",
      },
  },
  {
      jurisdiction: {
          code: "ao",
          name: "Angola",
          country: "Angola",
          full_name: "Angola",
      },
  },
  {
      jurisdiction: {
          code: "ai",
          name: "Anguilla",
          country: "Anguilla",
          full_name: "Anguilla",
      },
  },
  {
      jurisdiction: {
          code: "ag",
          name: "Antigua And Barbuda",
          country: "Antigua And Barbuda",
          full_name: "Antigua And Barbuda",
      },
  },
  {
      jurisdiction: {
          code: "ar",
          name: "Argentina",
          country: "Argentina",
          full_name: "Argentina",
      },
  },
  {
      jurisdiction: {
          code: "am",
          name: "Armenia",
          country: "Armenia",
          full_name: "Armenia",
      },
  },
  {
      jurisdiction: {
          code: "aw",
          name: "Aruba",
          country: "Aruba",
          full_name: "Aruba",
      },
  },
  {
      jurisdiction: {
          code: "au",
          name: "Australia",
          country: "Australia",
          full_name: "Australia",
      },
  },
  {
      jurisdiction: {
          code: "at",
          name: "Austria",
          country: "Austria",
          full_name: "Austria",
      },
  },
  {
      jurisdiction: {
          code: "az",
          name: "Azerbaijan",
          country: "Azerbaijan",
          full_name: "Azerbaijan",
      },
  },
  {
      jurisdiction: {
          code: "bs",
          name: "Bahamas",
          country: "Bahamas",
          full_name: "Bahamas",
      },
  },
  {
      jurisdiction: {
          code: "bh",
          name: "Bahrain",
          country: "Bahrain",
          full_name: "Bahrain",
      },
  },
  {
      jurisdiction: {
          code: "bd",
          name: "Bangladesh",
          country: "Bangladesh",
          full_name: "Bangladesh",
      },
  },
  {
      jurisdiction: {
          code: "bb",
          name: "Barbados",
          country: "Barbados",
          full_name: "Barbados",
      },
  },
  {
      jurisdiction: {
          code: "by",
          name: "Belarus",
          country: "Belarus",
          full_name: "Belarus",
      },
  },
  {
      jurisdiction: {
          code: "be",
          name: "Belgium",
          country: "Belgium",
          full_name: "Belgium",
      },
  },
  {
      jurisdiction: {
          code: "bz",
          name: "Belize",
          country: "Belize",
          full_name: "Belize",
      },
  },
  {
      jurisdiction: {
          code: "bj",
          name: "Benin",
          country: "Benin",
          full_name: "Benin",
      },
  },
  {
      jurisdiction: {
          code: "bm",
          name: "Bermuda",
          country: "Bermuda",
          full_name: "Bermuda",
      },
  },
  {
      jurisdiction: {
          code: "bt",
          name: "Bhutan",
          country: "Bhutan",
          full_name: "Bhutan",
      },
  },
  {
      jurisdiction: {
          code: "bo",
          name: "Bolivia",
          country: "Bolivia",
          full_name: "Bolivia",
      },
  },
  {
      jurisdiction: {
          code: "ba",
          name: "Bosnia And Herzegovina",
          country: "Bosnia And Herzegovina",
          full_name: "Bosnia And Herzegovina",
      },
  },
  {
      jurisdiction: {
          code: "bw",
          name: "Botswana",
          country: "Botswana",
          full_name: "Botswana",
      },
  },
  {
      jurisdiction: {
          code: "br",
          name: "Brazil",
          country: "Brazil",
          full_name: "Brazil",
      },
  },
  {
      jurisdiction: {
          code: "bn",
          name: "Brunei Darussalam",
          country: "Brunei Darussalam",
          full_name: "Brunei Darussalam",
      },
  },
  {
      jurisdiction: {
          code: "bg",
          name: "Bulgaria",
          country: "Bulgaria",
          full_name: "Bulgaria",
      },
  },
  {
      jurisdiction: {
          code: "bf",
          name: "Burkina Faso",
          country: "Burkina Faso",
          full_name: "Burkina Faso",
      },
  },
  {
      jurisdiction: {
          code: "bi",
          name: "Burundi",
          country: "Burundi",
          full_name: "Burundi",
      },
  },
  {
      jurisdiction: {
          code: "ci",
          name: "C?te D'ivoire",
          country: "C?te D'ivoire",
          full_name: "C?te D'ivoire",
      },
  },
  {
      jurisdiction: {
          code: "kh",
          name: "Cambodia",
          country: "Cambodia",
          full_name: "Cambodia",
      },
  },
  {
      jurisdiction: {
          code: "cm",
          name: "Cameroon",
          country: "Cameroon",
          full_name: "Cameroon",
      },
  },
  {
      jurisdiction: {
          code: "ca",
          name: "Canada",
          country: "Canada",
          full_name: "Canada",
      },
  },
  {
      jurisdiction: {
          code: "cv",
          name: "Cape Verde",
          country: "Cape Verde",
          full_name: "Cape Verde",
      },
  },
  {
      jurisdiction: {
          code: "ky",
          name: "Cayman Islands",
          country: "Cayman Islands",
          full_name: "Cayman Islands",
      },
  },
  {
      jurisdiction: {
          code: "cf",
          name: "Central African Republic",
          country: "Central African Republic",
          full_name: "Central African Republic",
      },
  },
  {
      jurisdiction: {
          code: "td",
          name: "Chad",
          country: "Chad",
          full_name: "Chad",
      },
  },
  {
      jurisdiction: {
          code: "cl",
          name: "Chile",
          country: "Chile",
          full_name: "Chile",
      },
  },
  {
      jurisdiction: {
          code: "cn",
          name: "China",
          country: "China",
          full_name: "China",
      },
  },
  {
      jurisdiction: {
          code: "co",
          name: "Colombia",
          country: "Colombia",
          full_name: "Colombia",
      },
  },
  {
      jurisdiction: {
          code: "km",
          name: "Comoros",
          country: "Comoros",
          full_name: "Comoros",
      },
  },
  {
      jurisdiction: {
          code: "cg",
          name: "Congo",
          country: "Congo",
          full_name: "Congo",
      },
  },
  {
      jurisdiction: {
          code: "cd",
          name: "Congo, Democratic Republic Of The",
          country: "Congo, Democratic Republic Of The",
          full_name: "Congo, Democratic Republic Of The",
      },
  },
  {
      jurisdiction: {
          code: "ck",
          name: "Cook Islands",
          country: "Cook Islands",
          full_name: "Cook Islands",
      },
  },
  {
      jurisdiction: {
          code: "cr",
          name: "Costa Rica",
          country: "Costa Rica",
          full_name: "Costa Rica",
      },
  },
  {
      jurisdiction: {
          code: "hr",
          name: "Croatia",
          country: "Croatia",
          full_name: "Croatia",
      },
  },
  {
      jurisdiction: {
          code: "cu",
          name: "Cuba",
          country: "Cuba",
          full_name: "Cuba",
      },
  },
  {
      jurisdiction: {
          code: "cy",
          name: "Cyprus",
          country: "Cyprus",
          full_name: "Cyprus",
      },
  },
  {
      jurisdiction: {
          code: "cz",
          name: "Czech Republic",
          country: "Czech Republic",
          full_name: "Czech Republic",
      },
  },
  {
      jurisdiction: {
          code: "dk",
          name: "Denmark",
          country: "Denmark",
          full_name: "Denmark",
      },
  },
  {
      jurisdiction: {
          code: "dj",
          name: "Djibouti",
          country: "Djibouti",
          full_name: "Djibouti",
      },
  },
  {
      jurisdiction: {
          code: "dm",
          name: "Dominica",
          country: "Dominica",
          full_name: "Dominica",
      },
  },
  {
      jurisdiction: {
          code: "do",
          name: "Dominican Republic",
          country: "Dominican Republic",
          full_name: "Dominican Republic",
      },
  },
  {
      jurisdiction: {
          code: "ec",
          name: "Ecuador",
          country: "Ecuador",
          full_name: "Ecuador",
      },
  },
  {
      jurisdiction: {
          code: "eg",
          name: "Egypt",
          country: "Egypt",
          full_name: "Egypt",
      },
  },
  {
      jurisdiction: {
          code: "sv",
          name: "El Salvador",
          country: "El Salvador",
          full_name: "El Salvador",
      },
  },
  {
      jurisdiction: {
          code: "gq",
          name: "Equatorial Guinea",
          country: "Equatorial Guinea",
          full_name: "Equatorial Guinea",
      },
  },
  {
      jurisdiction: {
          code: "er",
          name: "Eritrea",
          country: "Eritrea",
          full_name: "Eritrea",
      },
  },
  {
      jurisdiction: {
          code: "ee",
          name: "Estonia",
          country: "Estonia",
          full_name: "Estonia",
      },
  },
  {
      jurisdiction: {
          code: "et",
          name: "Ethiopia",
          country: "Ethiopia",
          full_name: "Ethiopia",
      },
  },
  {
      jurisdiction: {
          code: "fk",
          name: "Falkland Islands",
          country: "Falkland Islands",
          full_name: "Falkland Islands",
      },
  },
  {
      jurisdiction: {
          code: "fo",
          name: "Faroe Islands",
          country: "Faroe Islands",
          full_name: "Faroe Islands",
      },
  },
  {
      jurisdiction: {
          code: "fj",
          name: "Fiji",
          country: "Fiji",
          full_name: "Fiji",
      },
  },
  {
      jurisdiction: {
          code: "fi",
          name: "Finland",
          country: "Finland",
          full_name: "Finland",
      },
  },
  {
      jurisdiction: {
          code: "fr",
          name: "France",
          country: "France",
          full_name: "France",
      },
  },
  {
      jurisdiction: {
          code: "gf",
          name: "French Guiana",
          country: "French Guiana",
          full_name: "French Guiana",
      },
  },
  {
      jurisdiction: {
          code: "pf",
          name: "French Polynesia",
          country: "French Polynesia",
          full_name: "French Polynesia",
      },
  },
  {
      jurisdiction: {
          code: "ga",
          name: "Gabon",
          country: "Gabon",
          full_name: "Gabon",
      },
  },
  {
      jurisdiction: {
          code: "gm",
          name: "Gambia",
          country: "Gambia",
          full_name: "Gambia",
      },
  },
  {
      jurisdiction: {
          code: "?4",
          name: "Gaza Strip",
          country: "Gaza Strip",
          full_name: "Gaza Strip",
      },
  },
  {
      jurisdiction: {
          code: "ge",
          name: "Georgia",
          country: "Georgia",
          full_name: "Georgia",
      },
  },
  {
      jurisdiction: {
          code: "de",
          name: "Germany",
          country: "Germany",
          full_name: "Germany",
      },
  },
  {
      jurisdiction: {
          code: "gh",
          name: "Ghana",
          country: "Ghana",
          full_name: "Ghana",
      },
  },
  {
      jurisdiction: {
          code: "gi",
          name: "Gibraltar",
          country: "Gibraltar",
          full_name: "Gibraltar",
      },
  },
  {
      jurisdiction: {
          code: "?2",
          name: "Golan Heights",
          country: "Golan Heights",
          full_name: "Golan Heights",
      },
  },
  {
      jurisdiction: {
          code: "gr",
          name: "Greece",
          country: "Greece",
          full_name: "Greece",
      },
  },
  {
      jurisdiction: {
          code: "gl",
          name: "Greenland",
          country: "Greenland",
          full_name: "Greenland",
      },
  },
  {
      jurisdiction: {
          code: "gd",
          name: "Grenada",
          country: "Grenada",
          full_name: "Grenada",
      },
  },
  {
      jurisdiction: {
          code: "gp",
          name: "Guadeloupe",
          country: "Guadeloupe",
          full_name: "Guadeloupe",
      },
  },
  {
      jurisdiction: {
          code: "gu",
          name: "Guam",
          country: "Guam",
          full_name: "Guam",
      },
  },
  {
      jurisdiction: {
          code: "gt",
          name: "Guatemala",
          country: "Guatemala",
          full_name: "Guatemala",
      },
  },
  {
      jurisdiction: {
          code: "gn",
          name: "Guinea",
          country: "Guinea",
          full_name: "Guinea",
      },
  },
  {
      jurisdiction: {
          code: "gw",
          name: "Guinea-bissau",
          country: "Guinea-bissau",
          full_name: "Guinea-bissau",
      },
  },
  {
      jurisdiction: {
          code: "gy",
          name: "Guyana",
          country: "Guyana",
          full_name: "Guyana",
      },
  },
  {
      jurisdiction: {
          code: "ht",
          name: "Haiti",
          country: "Haiti",
          full_name: "Haiti",
      },
  },
  {
      jurisdiction: {
          code: "hn",
          name: "Honduras",
          country: "Honduras",
          full_name: "Honduras",
      },
  },
  {
      jurisdiction: {
          code: "hk",
          name: "Hong Kong",
          country: "Hong Kong",
          full_name: "Hong Kong",
      },
  },
  {
      jurisdiction: {
          code: "hu",
          name: "Hungary",
          country: "Hungary",
          full_name: "Hungary",
      },
  },
  {
      jurisdiction: {
          code: "is",
          name: "Iceland",
          country: "Iceland",
          full_name: "Iceland",
      },
  },
  {
      jurisdiction: {
          code: "in",
          name: "India",
          country: "India",
          full_name: "India",
      },
  },
  {
      jurisdiction: {
          code: "id",
          name: "Indonesia",
          country: "Indonesia",
          full_name: "Indonesia",
      },
  },
  {
      jurisdiction: {
          code: "ir",
          name: "Iran",
          country: "Iran",
          full_name: "Iran",
      },
  },
  {
      jurisdiction: {
          code: "iq",
          name: "Iraq",
          country: "Iraq",
          full_name: "Iraq",
      },
  },
  {
      jurisdiction: {
          code: "ie",
          name: "Ireland",
          country: "Ireland",
          full_name: "Ireland",
      },
  },
  {
      jurisdiction: {
          code: "il",
          name: "Israel",
          country: "Israel",
          full_name: "Israel",
      },
  },
  {
      jurisdiction: {
          code: "it",
          name: "Italy",
          country: "Italy",
          full_name: "Italy",
      },
  },
  {
      jurisdiction: {
          code: "jm",
          name: "Jamaica",
          country: "Jamaica",
          full_name: "Jamaica",
      },
  },
  {
      jurisdiction: {
          code: "jp",
          name: "Japan",
          country: "Japan",
          full_name: "Japan",
      },
  },
  {
      jurisdiction: {
          code: "jo",
          name: "Jordan",
          country: "Jordan",
          full_name: "Jordan",
      },
  },
  {
      jurisdiction: {
          code: "kz",
          name: "Kazakhstan",
          country: "Kazakhstan",
          full_name: "Kazakhstan",
      },
  },
  {
      jurisdiction: {
          code: "ke",
          name: "Kenya",
          country: "Kenya",
          full_name: "Kenya",
      },
  },
  {
      jurisdiction: {
          code: "ki",
          name: "Kiribati",
          country: "Kiribati",
          full_name: "Kiribati",
      },
  },
  {
      jurisdiction: {
          code: "kr",
          name: "Korea",
          country: "Korea",
          full_name: "Korea",
      },
  },
  {
      jurisdiction: {
          code: "kp",
          name: "Korea, Democratic People's Republic Of",
          country: "Korea, Democratic People's Republic Of",
          full_name: "Korea, Democratic People's Republic Of",
      },
  },
  {
      jurisdiction: {
          code: "kw",
          name: "Kuwait",
          country: "Kuwait",
          full_name: "Kuwait",
      },
  },
  {
      jurisdiction: {
          code: "kg",
          name: "Kyrgyzstan",
          country: "Kyrgyzstan",
          full_name: "Kyrgyzstan",
      },
  },
  {
      jurisdiction: {
          code: "la",
          name: "Laos",
          country: "Laos",
          full_name: "Laos",
      },
  },
  {
      jurisdiction: {
          code: "lv",
          name: "Latvia",
          country: "Latvia",
          full_name: "Latvia",
      },
  },
  {
      jurisdiction: {
          code: "lb",
          name: "Lebanon",
          country: "Lebanon",
          full_name: "Lebanon",
      },
  },
  {
      jurisdiction: {
          code: "ls",
          name: "Lesotho",
          country: "Lesotho",
          full_name: "Lesotho",
      },
  },
  {
      jurisdiction: {
          code: "lr",
          name: "Liberia",
          country: "Liberia",
          full_name: "Liberia",
      },
  },
  {
      jurisdiction: {
          code: "ly",
          name: "Libya",
          country: "Libya",
          full_name: "Libya",
      },
  },
  {
      jurisdiction: {
          code: "li",
          name: "Liechtenstein",
          country: "Liechtenstein",
          full_name: "Liechtenstein",
      },
  },
  {
      jurisdiction: {
          code: "lt",
          name: "Lithuania",
          country: "Lithuania",
          full_name: "Lithuania",
      },
  },
  {
      jurisdiction: {
          code: "lu",
          name: "Luxembourg",
          country: "Luxembourg",
          full_name: "Luxembourg",
      },
  },
  {
      jurisdiction: {
          code: "mo",
          name: "Macau",
          country: "Macau",
          full_name: "Macau",
      },
  },
  {
      jurisdiction: {
          code: "mk",
          name: "Macedonia",
          country: "Macedonia",
          full_name: "Macedonia",
      },
  },
  {
      jurisdiction: {
          code: "mg",
          name: "Madagascar",
          country: "Madagascar",
          full_name: "Madagascar",
      },
  },
  {
      jurisdiction: {
          code: "mw",
          name: "Malawi",
          country: "Malawi",
          full_name: "Malawi",
      },
  },
  {
      jurisdiction: {
          code: "my",
          name: "Malaysia",
          country: "Malaysia",
          full_name: "Malaysia",
      },
  },
  {
      jurisdiction: {
          code: "mv",
          name: "Maldives",
          country: "Maldives",
          full_name: "Maldives",
      },
  },
  {
      jurisdiction: {
          code: "ml",
          name: "Mali",
          country: "Mali",
          full_name: "Mali",
      },
  },
  {
      jurisdiction: {
          code: "mt",
          name: "Malta",
          country: "Malta",
          full_name: "Malta",
      },
  },
  {
      jurisdiction: {
          code: "mh",
          name: "Marshall Islands",
          country: "Marshall Islands",
          full_name: "Marshall Islands",
      },
  },
  {
      jurisdiction: {
          code: "mq",
          name: "Martinique",
          country: "Martinique",
          full_name: "Martinique",
      },
  },
  {
      jurisdiction: {
          code: "mr",
          name: "Mauritania",
          country: "Mauritania",
          full_name: "Mauritania",
      },
  },
  {
      jurisdiction: {
          code: "mu",
          name: "Mauritius",
          country: "Mauritius",
          full_name: "Mauritius",
      },
  },
  {
      jurisdiction: {
          code: "yt",
          name: "Mayotte",
          country: "Mayotte",
          full_name: "Mayotte",
      },
  },
  {
      jurisdiction: {
          code: "mx",
          name: "Mexico",
          country: "Mexico",
          full_name: "Mexico",
      },
  },
  {
      jurisdiction: {
          code: "fm",
          name: "Micronesia",
          country: "Micronesia",
          full_name: "Micronesia",
      },
  },
  {
      jurisdiction: {
          code: "md",
          name: "Moldova",
          country: "Moldova",
          full_name: "Moldova",
      },
  },
  {
      jurisdiction: {
          code: "mc",
          name: "Monaco",
          country: "Monaco",
          full_name: "Monaco",
      },
  },
  {
      jurisdiction: {
          code: "mn",
          name: "Mongolia",
          country: "Mongolia",
          full_name: "Mongolia",
      },
  },
  {
      jurisdiction: {
          code: "me",
          name: "Montenegro",
          country: "Montenegro",
          full_name: "Montenegro",
      },
  },
  {
      jurisdiction: {
          code: "ms",
          name: "Montserrat",
          country: "Montserrat",
          full_name: "Montserrat",
      },
  },
  {
      jurisdiction: {
          code: "ma",
          name: "Morocco",
          country: "Morocco",
          full_name: "Morocco",
      },
  },
  {
      jurisdiction: {
          code: "mz",
          name: "Mozambique",
          country: "Mozambique",
          full_name: "Mozambique",
      },
  },
  {
      jurisdiction: {
          code: "mm",
          name: "Myanmar",
          country: "Myanmar",
          full_name: "Myanmar",
      },
  },
  {
      jurisdiction: {
          code: "na",
          name: "Namibia",
          country: "Namibia",
          full_name: "Namibia",
      },
  },
  {
      jurisdiction: {
          code: "nr",
          name: "Nauru",
          country: "Nauru",
          full_name: "Nauru",
      },
  },
  {
      jurisdiction: {
          code: "np",
          name: "Nepal",
          country: "Nepal",
          full_name: "Nepal",
      },
  },
  {
      jurisdiction: {
          code: "nl",
          name: "Netherlands",
          country: "Netherlands",
          full_name: "Netherlands",
      },
  },
  {
      jurisdiction: {
          code: "?3",
          name: "Netherlands Antilles",
          country: "Netherlands Antilles",
          full_name: "Netherlands Antilles",
      },
  },
  {
      jurisdiction: {
          code: "nc",
          name: "New Caledonia",
          country: "New Caledonia",
          full_name: "New Caledonia",
      },
  },
  {
      jurisdiction: {
          code: "nz",
          name: "New Zealand",
          country: "New Zealand",
          full_name: "New Zealand",
      },
  },
  {
      jurisdiction: {
          code: "ni",
          name: "Nicaragua",
          country: "Nicaragua",
          full_name: "Nicaragua",
      },
  },
  {
      jurisdiction: {
          code: "ne",
          name: "Niger",
          country: "Niger",
          full_name: "Niger",
      },
  },
  {
      jurisdiction: {
          code: "ng",
          name: "Nigeria",
          country: "Nigeria",
          full_name: "Nigeria",
      },
  },
  {
      jurisdiction: {
          code: "nu",
          name: "Niue",
          country: "Niue",
          full_name: "Niue",
      },
  },
  {
      jurisdiction: {
          code: "nf",
          name: "Norfolk Island",
          country: "Norfolk Island",
          full_name: "Norfolk Island",
      },
  },
  {
      jurisdiction: {
          code: "mp",
          name: "Northern Mariana Islands",
          country: "Northern Mariana Islands",
          full_name: "Northern Mariana Islands",
      },
  },
  {
      jurisdiction: {
          code: "no",
          name: "Norway",
          country: "Norway",
          full_name: "Norway",
      },
  },
  {
      jurisdiction: {
          code: "om",
          name: "Oman",
          country: "Oman",
          full_name: "Oman",
      },
  },
  {
      jurisdiction: {
          code: "pk",
          name: "Pakistan",
          country: "Pakistan",
          full_name: "Pakistan",
      },
  },
  {
      jurisdiction: {
          code: "pw",
          name: "Palau",
          country: "Palau",
          full_name: "Palau",
      },
  },
  {
      jurisdiction: {
          code: "pa",
          name: "Panama",
          country: "Panama",
          full_name: "Panama",
      },
  },
  {
      jurisdiction: {
          code: "pg",
          name: "Papua New Guinea",
          country: "Papua New Guinea",
          full_name: "Papua New Guinea",
      },
  },
  {
      jurisdiction: {
          code: "py",
          name: "Paraguay",
          country: "Paraguay",
          full_name: "Paraguay",
      },
  },
  {
      jurisdiction: {
          code: "pe",
          name: "Peru",
          country: "Peru",
          full_name: "Peru",
      },
  },
  {
      jurisdiction: {
          code: "ph",
          name: "Philippines",
          country: "Philippines",
          full_name: "Philippines",
      },
  },
  {
      jurisdiction: {
          code: "pl",
          name: "Poland",
          country: "Poland",
          full_name: "Poland",
      },
  },
  {
      jurisdiction: {
          code: "pt",
          name: "Portugal",
          country: "Portugal",
          full_name: "Portugal",
      },
  },
  {
      jurisdiction: {
          code: "pr",
          name: "Puerto Rico",
          country: "Puerto Rico",
          full_name: "Puerto Rico",
      },
  },
  {
      jurisdiction: {
          code: "qa",
          name: "Qatar",
          country: "Qatar",
          full_name: "Qatar",
      },
  },
  {
      jurisdiction: {
          code: "ro",
          name: "Romania",
          country: "Romania",
          full_name: "Romania",
      },
  },
  {
      jurisdiction: {
          code: "ru",
          name: "Russia",
          country: "Russia",
          full_name: "Russia",
      },
  },
  {
      jurisdiction: {
          code: "rw",
          name: "Rwanda",
          country: "Rwanda",
          full_name: "Rwanda",
      },
  },
  {
      jurisdiction: {
          code: "re",
          name: "Rï¿½ï¿½union",
          country: "Rï¿½ï¿½union",
          full_name: "Rï¿½ï¿½union",
      },
  },
  {
      jurisdiction: {
          code: "sh",
          name: "Saint Helena",
          country: "Saint Helena",
          full_name: "Saint Helena",
      },
  },
  {
      jurisdiction: {
          code: "kn",
          name: "Saint Kitts And Nevis",
          country: "Saint Kitts And Nevis",
          full_name: "Saint Kitts And Nevis",
      },
  },
  {
      jurisdiction: {
          code: "lc",
          name: "Saint Lucia",
          country: "Saint Lucia",
          full_name: "Saint Lucia",
      },
  },
  {
      jurisdiction: {
          code: "pm",
          name: "Saint Pierre And Miquelon",
          country: "Saint Pierre And Miquelon",
          full_name: "Saint Pierre And Miquelon",
      },
  },
  {
      jurisdiction: {
          code: "vc",
          name: "Saint Vincent And The Grenadines",
          country: "Saint Vincent And The Grenadines",
          full_name: "Saint Vincent And The Grenadines",
      },
  },
  {
      jurisdiction: {
          code: "ws",
          name: "Samoa",
          country: "Samoa",
          full_name: "Samoa",
      },
  },
  {
      jurisdiction: {
          code: "sm",
          name: "San Marino",
          country: "San Marino",
          full_name: "San Marino",
      },
  },
  {
      jurisdiction: {
          code: "st",
          name: "Sao Tome And Principe",
          country: "Sao Tome And Principe",
          full_name: "Sao Tome And Principe",
      },
  },
  {
      jurisdiction: {
          code: "sa",
          name: "Saudi Arabia",
          country: "Saudi Arabia",
          full_name: "Saudi Arabia",
      },
  },
  {
      jurisdiction: {
          code: "sn",
          name: "Senegal",
          country: "Senegal",
          full_name: "Senegal",
      },
  },
  {
      jurisdiction: {
          code: "?6",
          name: "Serbia",
          country: "Serbia",
          full_name: "Serbia",
      },
  },
  {
      jurisdiction: {
          code: "sc",
          name: "Seychelles",
          country: "Seychelles",
          full_name: "Seychelles",
      },
  },
  {
      jurisdiction: {
          code: "sl",
          name: "Sierra Leone",
          country: "Sierra Leone",
          full_name: "Sierra Leone",
      },
  },
  {
      jurisdiction: {
          code: "sg",
          name: "Singapore",
          country: "Singapore",
          full_name: "Singapore",
      },
  },
  {
      jurisdiction: {
          code: "sk",
          name: "Slovakia",
          country: "Slovakia",
          full_name: "Slovakia",
      },
  },
  {
      jurisdiction: {
          code: "si",
          name: "Slovenia",
          country: "Slovenia",
          full_name: "Slovenia",
      },
  },
  {
      jurisdiction: {
          code: "sb",
          name: "Solomon Islands",
          country: "Solomon Islands",
          full_name: "Solomon Islands",
      },
  },
  {
      jurisdiction: {
          code: "so",
          name: "Somalia",
          country: "Somalia",
          full_name: "Somalia",
      },
  },
  {
      jurisdiction: {
          code: "za",
          name: "South Africa",
          country: "South Africa",
          full_name: "South Africa",
      },
  },
  {
      jurisdiction: {
          code: "es",
          name: "Spain",
          country: "Spain",
          full_name: "Spain",
      },
  },
  {
      jurisdiction: {
          code: "lk",
          name: "Sri Lanka",
          country: "Sri Lanka",
          full_name: "Sri Lanka",
      },
  },
  {
      jurisdiction: {
          code: "sd",
          name: "Sudan",
          country: "Sudan",
          full_name: "Sudan",
      },
  },
  {
      jurisdiction: {
          code: "sr",
          name: "Suriname",
          country: "Suriname",
          full_name: "Suriname",
      },
  },
  {
      jurisdiction: {
          code: "sj",
          name: "Svalbard And Jan Mayen",
          country: "Svalbard And Jan Mayen",
          full_name: "Svalbard And Jan Mayen",
      },
  },
  {
      jurisdiction: {
          code: "sz",
          name: "Swaziland",
          country: "Swaziland",
          full_name: "Swaziland",
      },
  },
  {
      jurisdiction: {
          code: "se",
          name: "Sweden",
          country: "Sweden",
          full_name: "Sweden",
      },
  },
  {
      jurisdiction: {
          code: "ch",
          name: "Switzerland",
          country: "Switzerland",
          full_name: "Switzerland",
      },
  },
  {
      jurisdiction: {
          code: "sy",
          name: "Syria",
          country: "Syria",
          full_name: "Syria",
      },
  },
  {
      jurisdiction: {
          code: "tw",
          name: "Taiwan",
          country: "Taiwan",
          full_name: "Taiwan",
      },
  },
  {
      jurisdiction: {
          code: "tj",
          name: "Tajikistan",
          country: "Tajikistan",
          full_name: "Tajikistan",
      },
  },
  {
      jurisdiction: {
          code: "tz",
          name: "Tanzania",
          country: "Tanzania",
          full_name: "Tanzania",
      },
  },
  {
      jurisdiction: {
          code: "th",
          name: "Thailand",
          country: "Thailand",
          full_name: "Thailand",
      },
  },
  {
      jurisdiction: {
          code: "tl",
          name: "Timor-leste",
          country: "Timor-leste",
          full_name: "Timor-leste",
      },
  },
  {
      jurisdiction: {
          code: "tg",
          name: "Togo",
          country: "Togo",
          full_name: "Togo",
      },
  },
  {
      jurisdiction: {
          code: "tk",
          name: "Tokelau",
          country: "Tokelau",
          full_name: "Tokelau",
      },
  },
  {
      jurisdiction: {
          code: "to",
          name: "Tonga",
          country: "Tonga",
          full_name: "Tonga",
      },
  },
  {
      jurisdiction: {
          code: "tt",
          name: "Trinidad And Tobago",
          country: "Trinidad And Tobago",
          full_name: "Trinidad And Tobago",
      },
  },
  {
      jurisdiction: {
          code: "tn",
          name: "Tunisia",
          country: "Tunisia",
          full_name: "Tunisia",
      },
  },
  {
      jurisdiction: {
          code: "tr",
          name: "Turkey",
          country: "Turkey",
          full_name: "Turkey",
      },
  },
  {
      jurisdiction: {
          code: "tm",
          name: "Turkmenistan",
          country: "Turkmenistan",
          full_name: "Turkmenistan",
      },
  },
  {
      jurisdiction: {
          code: "tc",
          name: "Turks And Caicos Islands",
          country: "Turks And Caicos Islands",
          full_name: "Turks And Caicos Islands",
      },
  },
  {
      jurisdiction: {
          code: "tv",
          name: "Tuvalu",
          country: "Tuvalu",
          full_name: "Tuvalu",
      },
  },
  {
      jurisdiction: {
          code: "ug",
          name: "Uganda",
          country: "Uganda",
          full_name: "Uganda",
      },
  },
  {
      jurisdiction: {
          code: "ua",
          name: "Ukraine",
          country: "Ukraine",
          full_name: "Ukraine",
      },
  },
  {
      jurisdiction: {
          code: "?5",
          name: "Un Neutral Zone",
          country: "Un Neutral Zone",
          full_name: "Un Neutral Zone",
      },
  },
  {
      jurisdiction: {
          code: "ae",
          name: "United Arab Emirates",
          country: "United Arab Emirates",
          full_name: "United Arab Emirates",
      },
  },
  {
      jurisdiction: {
          code: "gb",
          name: "United Kingdom",
          country: "United Kingdom",
          full_name: "United Kingdom",
      },
  },
  {
      jurisdiction: {
          code: "us",
          name: "United States",
          country: "United States",
          full_name: "United States",
      },
  },
  {
      jurisdiction: {
          code: "uy",
          name: "Uruguay",
          country: "Uruguay",
          full_name: "Uruguay",
      },
  },
  {
      jurisdiction: {
          code: "uz",
          name: "Uzbekistan",
          country: "Uzbekistan",
          full_name: "Uzbekistan",
      },
  },
  {
      jurisdiction: {
          code: "vu",
          name: "Vanuatu",
          country: "Vanuatu",
          full_name: "Vanuatu",
      },
  },
  {
      jurisdiction: {
          code: "ve",
          name: "Venezuela",
          country: "Venezuela",
          full_name: "Venezuela",
      },
  },
  {
      jurisdiction: {
          code: "vn",
          name: "Viet Nam",
          country: "Viet Nam",
          full_name: "Viet Nam",
      },
  },
  {
      jurisdiction: {
          code: "vg",
          name: "Virgin Islands, British",
          country: "Virgin Islands, British",
          full_name: "Virgin Islands, British",
      },
  },
  {
      jurisdiction: {
          code: "vi",
          name: "Virgin Islands, U.s.",
          country: "Virgin Islands, U.s.",
          full_name: "Virgin Islands, U.s.",
      },
  },
  {
      jurisdiction: {
          code: "wf",
          name: "Wallis And Futuna",
          country: "Wallis And Futuna",
          full_name: "Wallis And Futuna",
      },
  },
  {
      jurisdiction: {
          code: "?1",
          name: "West Bank",
          country: "West Bank",
          full_name: "West Bank",
      },
  },
  {
      jurisdiction: {
          code: "eh",
          name: "Western Sahara",
          country: "Western Sahara",
          full_name: "Western Sahara",
      },
  },
  {
      jurisdiction: {
          code: "ye",
          name: "Yemen",
          country: "Yemen",
          full_name: "Yemen",
      },
  },
  {
      jurisdiction: {
          code: "zm",
          name: "Zambia",
          country: "Zambia",
          full_name: "Zambia",
      },
  },
  {
      jurisdiction: {
          code: "zw",
          name: "Zimbabwe",
          country: "Zimbabwe",
          full_name: "Zimbabwe",
      },
  },
];


export {
    formMapper,
    TENANT_ROLES_MAP,
    allMonths,
    ENTITY_TYPES_DROPDOWN_CONFIG,
    DEFAULT_PACKAGE_ENTITY_TYPE,
    MINIMUM_ENTITY_COUNT,
    MAXIMUM_ENTITY_COUNT,
    DEFAULT_ENTITY_TYPE_NAME,
    ENTITY_TYPE_NAMES,
    EDD_CHECK_ID,
    MAXIMUM_CUSTOMIZED_FORMS_COUNT,
};
