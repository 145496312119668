import Vue from 'vue';
import {startCase} from "lodash";
import {setFavIcon} from "@shared/utils/functions"

const state = Vue.observable({
        user_email: '',
        user_name: 'user',
        login_screen_info: {}
});

export const mutations = {
  setUserName(val) {
    state.user_name = val;
  },
  setUserEmail(val){
    state.user_email = val
  },
  setLoginScreenInfo({data}) {
    let tenant = {
      // domain_name: "Neotas",
      ...data,
    }
    document.title = startCase(tenant.domain_name);
    setFavIcon(tenant?.logo_url);
    state.login_screen_info = data;
  }
}

export const getters = {
  getLoginScreenInfo: state.login_screen_info,
}

export default state;
