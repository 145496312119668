export let Store = {
    state: {
        loginTenantDetails: null,
        allJurisdiction: [],
        allCountryList: [],
    },
    mutations: {
        SET_TENANT_DETAILS(state, payload) {
            state.loginTenantDetails = payload;
            document.title = payload.domain_name;
        },
        SET_JURISDICTION(state, payload) {
            state.allJurisdiction = payload;
        },
        SET_COUNTRY_LIST(state, payload) {
            state.allCountryList = payload;
        }
    },
    getters: {
        getLoginTenantDetails: (state) => state.loginTenantDetails,
        getAllStoresJurisdiction: (state) => state.allJurisdiction,
        getAllCountryList: (state) => state.allCountryList,
    },
    actions: {
        setTenantDetails: ({ commit }, payload) => {
            commit("SET_TENANT_DETAILS", payload);
        },
        storeAllJurisdiction: ({commit}, payload) => {
            commit("SET_JURISDICTION", payload);
        },
        storeAllCountryList: ({commit}, payload) => {
            commit("SET_COUNTRY_LIST", payload);
        }
    },
};