export const FormAdmin = [
    {
        // name: "Form-Admin",
        path: "/form-admin",
        component: () => import(/* webpackChunkName: "form-admin" */ "./index.vue"),
        meta: {
            breadcrumb: [{ name: "Admin" }, { name: "Form List" }],
            customCrumbs: [],
            title: "Forms",
        },
        children: [
            {
                name: "Form-Admin",
                path: "/",
                component: () => import(/* webpackChunkName: "form-admin" */ "./pages/form-admin"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Form List" }],
                    customCrumbs: [],
                    title: "Forms",
                },
            },
            {
                name: "form admin details",
                path: "/form-admin/view/:id/setting",
                component: () => import(/* webpackChunkName: "form-admin" */ "./pages/form-draggable-section"),
                meta: {
                    breadcrumb: [{ name: "Admin" }, { name: "Form List" ,clickable:true , goTo:'/form-admin'}, { name: "Form Edit" }],
                    title: "Form Details",
                    requiredPermission: 'form.read'
                },
            },
        ],
    },
];
