// import { default as ax } from "axios";
// import store from "../../tenant-app/src/store";


// let axios = ax.create({
//     baseURL: `${process.env.VUE_APP_OSINT_API_BASE_URL}`,
// });

// axios.interceptors.request.use(
//     (config) => {
//         const headers = {
//             "X-API-TOKEN": process.env.VUE_APP_OSINT_API_KEY,
//             ...store.getters.getApiHeaders,
//             ...config.headers
//         }
//         const finalHeaders = {...config, headers}
//         return finalHeaders;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

// export default axios;

import { default as ax } from "axios";
import VueCookies from "vue-cookies";
import store from "@/store";

// import store from "@/store";
// import { APP_CONFIG } from "@/utils/constants";
import refreshTokeninterceptor from "./utils";


let axios = ax.create({
    baseURL: `${process.env.VUE_APP_OSINT_API_BASE_URL}`,
});

axios.interceptors.request.use(
    (config) => {
        let token = VueCookies.get("token");
        console.log(" VUE_APP_BACKEND_URL osintaxios", `${process.env.VUE_APP_BACKEND_URL}`)
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["x-case-id"] = store.getters.getNeoCaseId;
            config.headers["x-case-product"] = 'Old Nayi Company'
            config.headers["x-status"] = 'RUNNING'
            config.headers["x-refresh"] = false
            config.headers["x-wlp-reference"] = `${process.env.VUE_APP_BACKEND_URL}`;
            /*
            To keep some apis working ex(case list api) which dependes upon some header values,
            use this function.
            */
            // config.headers["X-Source"] = `(saas-platform) ${APP_CONFIG.name}/${APP_CONFIG.version}`;
            // config.headers["X-User-ID"] = store.getters.getUserAuthDetails.userId;
            // config.headers["X-CASE-ID"] = store.getters.getCaseId == null ? "" : store.getters.getCaseId;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, refreshTokeninterceptor(axios));
export default axios;
export const CancelToken = ax.CancelToken;

