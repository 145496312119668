import axios from "@/axios";
const { orderBy } = require("lodash");
import { getCaseEntities } from '@shared/case-entities/services';

export const CaseStore = {
    state: {
        caseData: null,
        caseStatusList: [],
        caseEntitiesData: {},
        showAddNewEntity: false,
        newEntityInfo: {
            entity_type: "",
            entity_name: "",
            entity_type_id: "",
            temporary_name: "",
        },
        ableToAccessCase: false,
        caseDataLoading: false,
    },

    mutations: {
        SET_CASE_DETAILS(state, caseData ) {
            state.caseData = caseData;
        },
        SET_CASE_STATUS_LISTS(state, caselist){
            state.caseStatusList = orderBy(caselist, 'status_order', 'asc')
        },
        SET_SHOW_ADD_NEW_ENTITY(state, payload) {
            state.showAddNewEntity = payload;
        },
        SET_CASE_ENTITIES_DATA(state, payload) {
            state.caseEntitiesData = payload;
        },
        SET_NEW_ENTITY_INFO(state, payload) {
            state.newEntityInfo[payload.key] = payload.value;
        },
        SET_CASE_OPEN_ACCESS(state, payload){
            state.ableToAccessCase = payload;
        },
        CASE_DATA_LOADING(state, payload){
            state.caseDataLoading = payload;
        }
    },

    getters: {
        getCaseData: state => state.caseData,
        getNeoCaseId: state => state?.caseData?.neo_case_id || "QAT-10",
        getCaseStatusList: state => state.caseStatusList,
        getCaseEntitiesData: state => state.caseEntitiesData,
        getShowAddNewEntity: state => state.showAddNewEntity,
        getNewEntityInfo: state => state.newEntityInfo,
        getIsPlusDisabled: state => {
            const { case_entities, case_entity_info } = state.caseEntitiesData ?? {};
            return case_entities?.length === case_entity_info?.max_case_entity_count;
        },
        getCaseAccessible: state => state.ableToAccessCase,
        getCaseDataLoading: state => state.caseDataLoading,
    },

    actions: {
        fetchCaseData: async ({ commit }, payload) => {
            commit('CASE_DATA_LOADING', true);
            if(payload){
                let case_id = payload
                const url = `/case/${case_id}`;
                try {
                    let { data } = await axios.get(url);
                    sessionStorage.setItem("client_id", data?.data?.client_id)
                    commit('SET_CASE_DETAILS', data.data)
                    commit('SET_CASE_OPEN_ACCESS', true)
                    commit('CASE_DATA_LOADING', false);
                } catch (error) {
                    if(error?.response?.status === 403 ){
                        commit('SET_CASE_OPEN_ACCESS', false)
                        commit('CASE_DATA_LOADING', false);
                    }
                    console.log("error>>>", error)

                    // Vue.$toast.warning("case not found!");
                }
            }
        },

        fetchCaseStatusLists: async({commit}, payload) => {
            const url = `/workflow/${payload.case_id}/status`;
            try {
                let { data } = await axios.get(url);
                commit('SET_CASE_STATUS_LISTS', data.data)
            } catch (error) {
                console.log("error>>>", error)
                // Vue.$toast.warning("case not found!");
            }
        },

        fetchCaseEntitiesData: async({ commit }, case_id) => {
            try {
              const data = await getCaseEntities(case_id);
              commit("SET_CASE_ENTITIES_DATA", data);
            } catch (err) {
              console.error("err", err);
            }
        },

        resetNewEntityInfo({ commit, state }) {
            for (const key in state.newEntityInfo) {
                commit("SET_NEW_ENTITY_INFO", { key, value: "" });
            }
        }
    }
};